<template>
  <section id="editarContenido">
    <b-tabs
      v-if="mostrarDatos"
      v-model="indexTabs"
      pills
    >
      <!-- Resource -->
      <b-tab
        v-if="language == base && !isMCH"
        active
      >
        <template #title>
          <feather-icon icon="FilmIcon" />
          <span>{{ $t("Resource") }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          :show="showResource"
        >
          <b-card>
            <b-card-header>
              <b-col>
                <b-card-title>{{ $t("editContent.media") }}</b-card-title>
              </b-col>
              <!-- Si la plataforma permite transcodificación y el recurso inicial se puede transcodificar, mostramos el botón de transcodificar contenido -->
              <b-col
                v-if="showMsgStateTranscode"
                md="2"
                style="text-align: end;"
              >
                <transcoding-info
                  :show-msg-state-transcode="showMsgStateTranscode"
                  :transcoding-state="transcodingState"
                  :id-transcoding="idTranscoding"
                  :could-transcode="couldTranscode"
                  :id-resource="contentResourceSelected"
                />
              </b-col>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col class="ml-2">
                  <b-form @submit.prevent="updateResourceInitial">
                    <b-form-group label-for="RecursoInicial">
                      <b-row>
                        <b-col>
                          <b-row class="mb-1">
                            <b-col>
                              {{ $t('initialResource') }}:
                              <b-badge
                                v-if="!fileUrl && !showResource"
                                class="ml-2"
                                variant="warning"
                              >
                                <feather-icon
                                  icon="AlertTriangleIcon"
                                  size="18"
                                />
                                {{ $t('SelRes') }}
                              </b-badge>
                            </b-col>
                          </b-row>
                          <b-row class="mt-2 mb-3 align-items-center">
                            <b-col lg="7">
                              <b-form-input
                                id="resourceName"
                                v-model="resourceName"
                                disabled
                              />
                            </b-col>
                            <b-col class="d-flex">
                              <div
                                class="mr-2 align-items-center"
                                @click="showModal"
                              >
                                <b-button
                                  v-if="(typeStream !== 'AWS' && typeStream !== 'WOW')"
                                  @click="showModal"
                                >
                                  {{ $t('Select') }}
                                </b-button>
                              </div>
                              <b-link
                                v-if="fileUrl"
                                class="ml-1"
                                :to="{
                                  name: 'resources-media-edit',
                                  params: { id: contentResourceSelected }
                                }"
                              >
                                <b-button variant="warning">
                                  {{ $t('dataGeneric.edit') }}
                                </b-button>
                              </b-link>
                            </b-col>
                          </b-row>
                          <b-row align-v="stretch">
                            <b-col align-self="stretch">
                              <video
                                v-if="fileUrl"
                                id="demo"
                                controls
                                class="
                                                                                                      video-js
                                                                                                      vjs-default-skin vjs-big-play-centered
                                                                                                      tamanio
                                                                                                    "
                                crossOrigin="dashboard.interactvty.com"
                                :poster="fileUrl.includes('mp3')
                                  ? require('@/assets/images/icons/audio.svg')
                                  : ''
                                "
                                data-setup="{&quot;liveui&quot;: true}"
                                @timeupdate="getTime()"
                                @loadeddata="videoMaker()"
                                @loadstart="videoMaker()"
                              >
                                <source
                                  :src="fileUrl"
                                  :type="fileUrl.includes('m3u8')
                                    ? 'application/x-mpegURL'
                                    : fileUrl.includes('mp4')
                                      ? 'video/mp4'
                                      : 'audio/mp3'
                                  "
                                >
                              </video>
                            </b-col>
                          </b-row>

                          <b-row
                            v-if="fileUrl"
                            cols="12"
                            class="align-items-center mt-2"
                          >
                            <b-col
                              md="6"
                              class="d-flex pl-0 mb-1"
                              style="justify-content: space-evenly;border-right: ridge;"
                            >
                              <span
                                class="cursor-pointer"
                                @click="tiempoVideo('-10')"
                              >
                                <feather-icon
                                  icon="RotateCcwIcon"
                                  size="18"
                                  class="mr-1"
                                />10
                              </span>

                              <span
                                class="cursor-pointer"
                                @click="tiempoVideo('-1')"
                              >
                                <feather-icon
                                  icon="RotateCcwIcon"
                                  size="18"
                                  class="mr-1"
                                />1
                              </span>

                              <span
                                class="cursor-pointer"
                                @click="tiempoVideo('+1')"
                              >
                                <feather-icon
                                  icon="RotateCwIcon"
                                  size="18"
                                  class="mr-1"
                                />
                                1
                              </span>

                              <span
                                class="cursor-pointer d-flex"
                                @click="tiempoVideo('+10')"
                              >
                                <feather-icon
                                  icon="RotateCwIcon"
                                  size="18"
                                  class="mr-1"
                                />10
                              </span>
                            </b-col>
                            <b-col
                              md="6"
                              class="d-flex align-items-center"
                              style="justify-content: space-evenly"
                              sm="12"
                            >
                              <b-form-input
                                v-model="tHour"
                                class="mr-1"
                                maxlength="2"
                                style="padding: 0; text-align: -webkit-center"
                                type="number"
                                :min="0"
                                @input="lengthHour"
                              />

                              <strong class="mr-1"> : </strong>

                              <b-form-input
                                id="tMinute"
                                v-model="tMinute"
                                maxlength="2"
                                class="mr-1"
                                style="padding: 0; text-align: -webkit-center"
                                type="number"
                                :min="0"
                                @input="lengthMinute"
                              />
                              <strong class="mr-1">:</strong>
                              <b-form-input
                                id="tSecond"
                                v-model="tSecond"
                                maxlength="2"
                                class="mr-1"
                                style="padding: 0; text-align: -webkit-center"
                                type="number"
                                :min="0"
                                @input="lengthSecond"
                              />
                              <b-button
                                style="white-space: nowrap"
                                @click="timePick()"
                              >
                                {{ $t('goTo') }}
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col lg="6">
                          <b-form
                            v-if="fileUrl && !isPodcast"
                            @submit.prevent="guardarPortada($event)"
                          >
                            <b-form-group label-for="RecursoInicial">
                              <b-row>
                                <b-col>{{ $t('GeneratePort') }}</b-col>
                              </b-row>
                              <b-row class="mt-1">
                                <b-col>
                                  <b-button
                                    :disabled="mediaOriginSelected == 'BUK'
                                      ? false
                                      : true
                                    "
                                    :variant="mediaOriginSelected == 'BUK' ? 'info' : ''
                                    "
                                    @click="generar()"
                                  >
                                    {{ $t('generate') }}
                                  </b-button>
                                </b-col>
                                <b-col v-if="mediaOriginSelected != 'BUK'">
                                  <b-alert
                                    show
                                    variant="warning"
                                  >
                                    <div class="alert-body">
                                      <feather-icon
                                        class="mr-25"
                                        icon="AlertTriangleIcon"
                                      />
                                      <span>{{ $t('originMediaRes') }}</span>
                                    </div>
                                  </b-alert>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col
                                  id="colCanvas"
                                  class="m-2"
                                >
                                  <canvas id="canvas" />
                                </b-col>
                              </b-row>
                              <b-row class="m-1">
                                <b-col v-if="gnBut">
                                  <b-form-group label-for="type">
                                    <b-form-select
                                      id="type"
                                      v-model="portadaSelect"
                                      :dir="$store.state.appConfig.isRTL
                                        ? 'rtl'
                                        : 'ltr'
                                      "
                                      :options="portadaType"
                                      label="text"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-button
                                    v-if="gnBut"
                                    type="submit"
                                    variant="success"
                                    :disabled="mediaOriginSelected == 'BUK'
                                      ? false
                                      : true
                                    "
                                  >
                                    {{ $t('dataGeneric.save') }}
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-form>
                </b-col>
              </b-row>
              <b-row
                v-if="videotype == 'AUD'"
                class="mt-2"
              >
                <b-col>
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('inputStream')"
                  >
                    <div class="d-flex">
                      <b-form-input
                        v-model="inputStream"
                        class="mr-1"
                        readonly
                      />
                      <b-button
                        v-clipboard:copy="inputStream"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.copy") }}
                      </b-button>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    class="mb-2 mr-1"
                    :label="$t('passStream')"
                  >
                    <div class="d-flex">
                      <b-form-input
                        v-model="passwordStream"
                        class="mr-1"
                        readonly
                      />
                      <b-button
                        v-clipboard:copy="passwordStream"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.copy") }}
                      </b-button>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>{{ $t('embed') }}:</b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-textarea
                    v-model="linkTexto"
                    disabled
                    rows="5"
                    class="mr-1"
                  />
                </b-col>
                <b-col>
                  <b-button
                    v-clipboard:copy="linkTexto"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                  >
                    {{ $t("dataGeneric.copy") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
      <!-- Información del contenido -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>{{ $t("editContent.title2") }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          :show="showData"
        >
          <b-form @submit="updateContentData">
            <b-card>
              <b-card-header>
                <b-card-title>
                  {{ $t("editContent.title2") }}
                  <b-badge
                    v-if="isMCH"
                    class="ml-2 "
                    variant="warning"
                    style="color:black"
                  >
                    <feather-icon
                      icon="AlertTriangleIcon"
                      size="30"
                    />
                    {{ $t('myChannelContent') }}
                  </b-badge>
                </b-card-title>
                <div class="d-flex">
                  <AIChatButton
                    ref="info"
                    :view="'info'"
                    :item="{ 'title': contentName, 'description': shortDescription, 'longDescription': contentDescription }"
                    :language="language"
                    :base="base"
                    :call-parent-method="getInfoToTranslate"
                    @updateData="getDataByAI"
                  />
                  <b-button
                    variant="success"
                    type="submit"
                  >
                    {{
                      $t("dataGeneric.save")
                    }}
                  </b-button>
                </div>
              </b-card-header>
              <b-card-body class="mr-1">
                <b-row>
                  <b-col
                    lg="12"
                    class="ml-2 mb-2"
                  >
                    <b-form-group class="mb-2">
                      <label
                        id="nameLabel"
                        class="col-form-label pt-0"
                      >
                        {{ $t('editContent.name') + (language == base ? ' *' : '') }}

                      </label>
                      <div
                        ref="nameDiv"
                        class=""
                      >
                        <b-form-input
                          v-model="contentName"
                          :placeholder="$t('firstName')"
                          maxlength="150"
                          minlength="3"
                          class="field"
                          :required="language == base"
                        />
                      </div>
                    </b-form-group>
                    <b-form-row>
                      <b-col>
                        <b-form-group>
                          <label
                            id="shortDescriptionLabel"
                            class="col-form-label pt-0"
                          >
                            {{ $t('category.shortDescription') + (language == base ? ' *' : '') }}
                          </label>
                          <div
                            ref="shortDescriptionDiv"
                            class=""
                          >
                            <b-form-input
                              id="des"
                              v-model="shortDescription"
                              class="field"
                              :placeholder="$t('category.shortDescription')"
                              maxlength="180"
                              :required="language == base"
                            />
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col v-if="language == base && !isLive && !isMCH">
                        <b-form-group :label="$t('epg.contentDuration')">
                          <b-form-input
                            id="duration"
                            v-model="duration"
                            autocomplete="new-password"
                            type="number"
                            step="any"
                            :placeholder="$t('epg.contentDuration')"
                          />
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col>
                        <b-form-group class="mb-2">
                          <label
                            id="descriptionLabel"
                            class="col-form-label pt-0 d-flex"
                          >
                            {{ $t('dataGeneric.description') }}

                          </label>
                          <div
                            ref="descriptionDiv"
                            class=""
                          >
                            <b-form-textarea
                              v-model="contentDescription"
                              :placeholder="$t('dataGeneric.description')"
                              class="field"
                              rows="3"
                            />
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col v-if="language == base && !isMCH">
                        <b-form-group
                          class="mb-2"
                          :label="$t('contents.comment')"
                        >
                          <b-form-textarea
                            v-model="comment"
                            :placeholder="$t('contents.comment')"
                            rows="3"
                          />
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col v-if="!isMCH">
                        <b-form-group class="mb-2">
                          <label
                            id="technicalDetailsLabel"
                            class="col-form-label pt-0"
                          >
                            {{ $t('category.factSheet') }}
                          </label>
                          <div
                            ref="technicalDetailsDiv"
                            class=""
                          >
                            <b-form-textarea
                              v-model="technicalDetails"
                              class="field"
                              :placeholder="$t('category.factSheet')"
                            />
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="language == base && !isMCH">
                      <b-col>
                        <b-form-group
                          :label="$t('contents.reference')"
                          class="mb-2"
                        >
                          <b-form-input
                            id="reference"
                            v-model="reference"
                            :placeholder="$t('contents.externalRefContent')"
                            maxlength="150"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col v-if="!isLive && !isMCH">
                        <b-form-group
                          label="opta ID"
                          class="mb-2"
                        >
                          <b-form-input
                            id="optaId"
                            v-model="optaId"
                            type="number"
                            placeholder="Opta ID"
                            maxlength="150"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group
                          :label="$t('code.type')"
                          class="mb-2"
                        >
                          <b-form-select
                            id="type"
                            v-model="type"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="text"
                            :disabled="isMCH"
                          >
                            <b-form-select-option
                              v-for="t in Object.keys(typeOptions)"
                              :key="t"
                              :disabled="shouldDisableOptionTypeContent()"
                              :value="t"
                            >
                              {{ $t(typeOptions[t]) }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="language == base && !isMCH">
                      <b-col>
                        <b-form-group
                          :label="$t('epg.genre')"
                          class="mb-2"
                        >
                          <b-form-select
                            id="genre"
                            v-model="selectedGenre"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="allGenre"
                            label="text"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          :label="$t('epg.ageClasific')"
                          class="mb-2"
                        >
                          <b-form-select
                            id="clasification"
                            v-model="selectedClasification"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          >
                            <b-form-select-option :value="null">
                              {{ $t('epg.ageClasific') }}
                            </b-form-select-option>
                            <b-form-select-option
                              v-for="s in allClasification"
                              :key="s.node.id"
                              :value="s.node.id"
                            >
                              <strong :style="'color:' + s.node.color + ';'">
                                {{ s.node.initial }} - {{ s.node.description }}
                              </strong>
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="language == base && !isMCH">
                      <b-col>
                        <b-form-group
                          class="mb-2"
                          :label="$t('contents.datePublishing')"
                        >
                          <flat-pickr
                            id="example-datepicker"
                            v-model="emisionDate"
                            class="form-control"
                            :placeholder="$t('contents.datePublishing')"
                            :config="{
                              enableTime: true,
                              enableSeconds: true,
                              dateFormat: 'Y-m-d H:i:S'
                            }"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        class="text-center"
                        style="align-self: center;"
                      >
                        <b-row>
                          <b-col>
                            {{ $t('subscriptions.createdAt') }}:
                            <strong>{{ fechasSplit(createdAt) }}</strong>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            {{ $t('subscriptions.modifiedAt') }}:
                            <strong>{{ fechasSplit(modifiedAt) }}</strong>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-form-row>
                    <b-form-row
                      v-if="language == base && !isMCH"
                      class="align-items-center"
                    >
                      <b-col md="2">
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('qualityCont')"
                        >
                          <b-form-select
                            v-model="quality"
                            class="mr-1"
                          >
                            <b-form-select-option
                              v-for="typ in Object.keys(qualityOptions)"
                              :key="typ"
                              :value="typ"
                            >
                              {{ qualityOptions[typ] }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="9">
                        <b-form-group
                          class="mb-2"
                          :label="$t('tagsCont')"
                        >
                          <v-select
                            v-model="selected"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            taggable
                            push-tags
                            :placeholder="$t('addTagsC')"
                            @input="modalTags()"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <feather-icon
                          v-b-tooltip.hover.top.v-primary="$t('addTags')
                          "
                          icon="InfoIcon"
                        />
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="language == base && !isMCH">
                      <b-col>
                        <b-form-group
                          class="mb-2"
                          :label="$t('badge')"
                        >
                          <b-form-select
                            v-model="contentBadge"
                            class="mr-1"
                          >
                            <b-form-select-option :value="null">
                              {{ $t('noBadge') }}
                            </b-form-select-option>
                            <b-form-select-option
                              v-for="ba in badges"
                              :key="ba.value"
                              :value="ba.value"
                            >
                              {{ ba.text }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <b-form-row v-if="language == base">
                      <b-col
                        v-if="!isLive && !isMCH && !disabledBuy"
                        md="2"
                      >
                        <b-form-group
                          class="mb-2"
                          :label="$t('editContent.pay')"
                        >
                          <b-form-checkbox
                            id="contentPremium"
                            v-model="contentPremium"
                            name="contentPremium"
                            :switch="true"
                          >
                            {{ $t("editContent.payContent") }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="!isPodcast && !isMCH"
                        class="d-flex ml-2"
                        md="2"
                      >
                        <b-form-group
                          class="mb-2 "
                          :label="$t('hasSub')"
                        >
                          <b-form-checkbox
                            id="hasSubtitle"
                            v-model="hasSubtitle"
                            name="hasSubtitle"
                            :switch="true"
                          >
                            {{ $t('subt') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="!disabledAdd"
                        md="2"
                      >
                        <b-form-group
                          class="mb-2 "
                          :label="$t('settingTabs.ads')"
                        >
                          <b-form-checkbox
                            id="contentAds"
                            v-model="contentAds"
                            name="contentAds"
                            :switch="true"
                            :disabled="disabledAdd"
                          >
                            {{ $t("editContent.adsQuest") }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="!isLive && !isMCH && !disabledDownload"
                        md="2"
                      >
                        <b-form-group
                          class="mb-2 "
                          :label="$t('contDownlable')"
                        >
                          <b-form-checkbox
                            id="isDownload"
                            v-model="isDownload"
                            name="isDownload"
                            :switch="true"
                          >
                            {{ $t('canDownload') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="!isMCH"
                        md="2"
                      >
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="$t('contents.categoryContents')"
                        >
                          <b-form-checkbox
                            id="showContentsCategories"
                            v-model="showContentsCategories"
                            name="showContentsCategories"
                            :switch="true"
                          >
                            {{ $t('showContentsCategories') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="isLive && !isMCH"
                        md="2"
                      >
                        <b-form-group
                          class="mb-2 mr-1"
                          label="DVR"
                        >
                          <b-form-checkbox
                            id="hasDvr"
                            v-model="hasDvr"
                            name="hasDvr"
                            :switch="true"
                          >
                            {{ $t('liveHasDvr') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="platformMode == 'FRM'"
                        md="2"
                      >
                        <b-form-group
                          class="mb-2 mr-1"
                          :label="'Freemium'"
                        >
                          <b-form-checkbox
                            id="isFreemium"
                            v-model="isFreemium"
                            name="isFreemium"
                            :switch="true"
                          >
                            {{ $t('freemium') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-tab>
      <!-- SEGMENTATION -->
      <b-tab v-if="language == base && userData.isSuperuser">
        <template #title>
          <feather-icon icon="TvIcon" />
          <span>{{ $t("segmentation") }}</span>
        </template>
        <b-card>
          <b-card-header>
            <b-card-title class="d-flex">
              {{ $t("segmDevices") }}
              <b-form-checkbox
                v-model="selectAll"
                class="ml-2"
                switch
                @change="clkSelectAll"
              >
                {{ $t('slider.allSelect') }}
              </b-form-checkbox>
            </b-card-title>

            <b-button
              variant="success"
              @click="updateSegmentationContent"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col class="m-1">
                <b-form-checkbox
                  v-model="targetWebActive"
                  switch
                >
                  Web
                </b-form-checkbox>
              </b-col>
              <b-col class="m-1">
                <b-form-checkbox
                  v-model="targetHbbtvActive"
                  switch
                >
                  HbbTv
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="m-1">
                <b-form-checkbox
                  v-model="targetMobileActive"
                  switch
                >
                  Mobile
                </b-form-checkbox>
              </b-col>
              <b-col class="m-1">
                <b-form-checkbox
                  v-model="targetSmarttvActive"
                  switch
                >
                  SmartTv
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-tab>
      <!-- Restricciones -->
      <b-tab v-if="language == base && !isLive && !isPodcast && !isMCH">
        <template #title>
          <feather-icon icon="UserXIcon" />
          <span>{{ $t('Restricciones') }}</span>
        </template>
        <b-row v-if="isDisplay && maxViews != 0">
          <b-col>
            <b-badge
              class="infoBadge p-1"
              variant="warning"
            >
              {{ $t('textRest', { maxViews: maxViews, nDisplay: nDisplay }) }}
            </b-badge>
          </b-col>
        </b-row>
        <b-row
          class="match-height mt-2"
          align-v="stretch"
        >
          <b-col
            md="6"
            align-self="stretch"
          >
            <b-card>
              <b-card-header>
                <b-card-title>{{ $t('resUser') }}</b-card-title>
                <b-button
                  variant="success"
                  @click="updateRestrictions(0)"
                >
                  {{
                    $t("dataGeneric.save")
                  }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col class="ml-2 mb-2">
                    <b-overlay
                      variant="transparent"
                      :opacity="1.00"
                      :blur="'2px'"
                      rounded="lg"
                      :show="showRestrictionsOne"
                    >
                      <b-form>
                        <b-form-row>
                          <b-col md="4">
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('contResVis')"
                            >
                              <b-form-checkbox
                                v-model="isDisplay"
                                :switch="true"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              v-show="isDisplay"
                              class="mb-2 mr-1"
                              :label="$t('nRes')"
                            >
                              <b-form-input
                                v-model="nDisplay"
                                type="number"
                                :disabled="!isDisplay"
                                maxlength="30"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                      </b-form>
                    </b-overlay>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col
            md="6"
            align-self="stretch"
          >
            <b-card>
              <b-card-header>
                <b-card-title>{{ $t('resByUser') }}</b-card-title>
                <b-button
                  variant="success"
                  @click="updateRestrictions(1)"
                >
                  {{
                    $t("dataGeneric.save")
                  }}
                </b-button>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col class="ml-2 mb-2">
                    <b-overlay
                      variant="transparent"
                      :opacity="1.00"
                      :blur="'2px'"
                      rounded="lg"
                      :show="showRestrictionsTwo"
                    >
                      <b-form>
                        <b-form-row>
                          <b-col>
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('limitNmax')"
                            >
                              <b-form-input
                                v-model="maxViews"
                                type="number"
                                maxlength="30"
                              />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row>
                          <b-col class="views-info">
                            <p>
                              {{ $t('ifRes') }}
                            </p>
                          </b-col>
                        </b-form-row>
                      </b-form>
                    </b-overlay>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <!-- Archivos del contenido  -->
      <b-tab>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>{{ $t("category.filesContent") }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          :show="showDesign"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t("category.filesContent") }}</b-card-title>
              <div class="d-flex">
                <b-button
                  v-if="mediaOriginSelectedDB === 'DIR'"
                  variant="primary"
                  class="mr-2"
                  @click="goToInternal"
                >
                  {{ $t("pasarInterno") }}
                </b-button>
                <AIChatButton
                  ref="image"
                  :disabled="mediaOriginSelectedDB == 'DIR'"
                  :view="'image'"
                  :item="{ 'title': contentName, 'description': shortDescription, 'longDescription': contentDescription }"
                  @updateData="getDataByAI"
                />
                <b-button
                  variant="success"
                  @click="updateDesignContent"
                >
                  {{
                    $t("dataGeneric.save")
                  }}
                </b-button>
              </div>
            </b-card-header>
            <b-row>
              <b-col
                lg="12"
                class="ml-2 mb-2"
              >
                <!-- <b-form> -->
                <validation-observer
                  ref="ArchivosRules"
                  tag="form"
                >
                  <b-form-group
                    v-if="language == base"
                    class="mb-2 mr-1"
                    :label="$t('resources.originMedia')"
                  >
                    <b-form-select
                      v-model="mediaOriginSelected"
                      class="mr-1"
                      :options="mediaOriginOptions"
                    />
                  </b-form-group>
                  <div>
                    <!-- FILA TV/WEB -->
                    <b-form-group class="m-1">
                      <label><strong>TV/Web</strong></label>
                      <b-row
                        style="justify-content: space-around;;"
                        align-v="stretch"
                      >
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? imageContentSelected : imageDataUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'imageData' + language"
                          :is-required="true"
                          :ai-element="'imageFolder'"
                          class="field"
                          :label="$t('contents.imgInternal')"
                          @clickAiImage="clickAiImage"
                          @saveImage="loadImage"
                        />
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? backgroundContentSelected : imageDataBckUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'imageDataBck' + language"
                          :label-info="$t('infoImage.background')"
                          :label="$t('contents.bckgInternal')"
                          :can-delete="true"
                          :ai-element="'imageBackground'"
                          @clickAiImage="clickAiImage"
                          @saveImage="loadImage"
                          @deleteImage="deleteImage"
                        />
                        <b-col cols="3" />
                      </b-row>
                    </b-form-group>
                    <!-- FILA MÓVIL -->
                    <b-form-group class="m-1">
                      <label><strong>{{ $t('Movil') }}</strong></label>
                      <b-row style="justify-content: space-around;;">
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? mobileImageContentSelected : imageDataMobUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'imageDataMob' + language"
                          :label="$t('contents.mobileImgInternal')"
                          :ai-element="'imageMobile'"
                          :can-delete="true"
                          @clickAiImage="clickAiImage"
                          @saveImage="loadImage"
                          @deleteImage="deleteImage"
                        />
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? backgroundMobileSelected : backgroundMobileUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'backgroundMobile' + language"
                          :label="$t('backMov')"
                          :label-info="$t('infoImage.backgroundMobile')"
                          :can-delete="true"
                          :ai-element="'imageBackgroundMobile'"
                          @clickAiImage="clickAiImage"
                          @saveImage="loadImage"
                          @deleteImage="deleteImage"
                        />
                        <b-col cols="3" />
                      </b-row>
                    </b-form-group>
                    <!-- FILA RECURSOS VARIOS -->
                    <b-form-group class="m-1">
                      <label><strong>{{ $t('otherRes') }}</strong></label>
                      <b-row style="justify-content: space-around;;">
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? imageAlternativeSelected : imageDataAltUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'imageDataAlt' + language"
                          :label="$t('editContent.image')"
                          :label-info="$t('infoImage.alternative')"
                          :can-delete="true"
                          :ai-element="'imageAlternative'"
                          @clickAiImage="clickAiImage"
                          @saveImage="loadImage"
                          @deleteImage="deleteImage"
                        />
                        <image-element-vue
                          :image-origin="mediaOriginSelected == 'BUK' ? titleImageContentSelected : imageDataTitUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'imageDataTit' + language"
                          :label="$t('contents.titleImgInternal')"
                          :can-delete="true"
                          :label-info="$t('infoImage.title')"
                          :ai-element="'imageTitle'"
                          @clickAiImage="clickAiImage"
                          @saveImage="loadImage"
                          @deleteImage="deleteImage"
                        />
                        <image-element-vue
                          v-if="!isMCH"
                          :image-origin="mediaOriginSelected == 'BUK' ? trailerContentContentSelected : imageDataTrUrl"
                          :media-origin-selected="mediaOriginSelected"
                          :image-type="'imageDataTr' + language"
                          :label="$t('contents.trailerInterno')"
                          :can-delete="true"
                          :video="true"
                          @saveImage="loadImage"
                          @deleteImage="deleteImage"
                        />
                        <b-col
                          v-else
                          md="3"
                          cols="4"
                        />
                      </b-row>
                    </b-form-group>
                  </div>
                </validation-observer>
                <!-- </b-form> -->
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-tab>
      <!-- Estado -->
      <b-tab v-if="language == base">
        <template #title>
          <feather-icon icon="CalendarIcon" />
          <span>{{ $t("editContent.status") }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          :show="showState"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t("editContent.status") }}</b-card-title>
              <b-button
                variant="success"
                @click="updateStateContent"
              >
                {{
                  $t("dataGeneric.save")
                }}
              </b-button>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  lg="12"
                  class="ml-2 mb-2"
                >
                  <b-form>
                    <b-form-group :label="$t('message.tableHeader.status')">
                      <b-form-select
                        id="contentStatus"
                        v-model="contentStatus"
                        :label="$t('message.tableHeader.status')"
                      >
                        <b-form-select-option
                          v-for="s in status"
                          :key="s.value"
                          :value="s.value"
                        >
                          {{ s.text }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                    <b-form-row
                      v-if="contentStatus == 'GRE'"
                      id="fechas"
                    >
                      <b-col>
                        <b-form-group
                          class="mb-2 mr-1"
                        >
                          <label class="d-flex justify-content-between mb-1">
                            <div>
                              {{ $t('contents.datePublication') }}
                            </div>
                            <div>
                              <feather-icon
                                v-if="publishingDate"
                                class="text-danger cursor-pointer ml-1"
                                icon="XOctagonIcon"
                                size="15"
                                @click="publishingDate=null"
                              />
                            </div>
                          </label>
                          <flat-pickr
                            v-model="publishingDate"
                            class="form-control"
                            :placeholder="$t('contents.datePublication')"
                            :config="{
                              enableTime: true,
                              enableSeconds: true,
                              dateFormat: 'Y-m-d H:i:S',
                              minDate: 'today'
                            }"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          class="mb-2 mr-1"
                        >
                          <label class="d-flex justify-content-between mb-1">
                            <div>
                              {{ $t('contents.dateFinishing') }}
                            </div>
                            <div>
                              <feather-icon
                                v-if="finishingDate"
                                class="text-danger cursor-pointer ml-1"
                                icon="XOctagonIcon"
                                size="15"
                                @click="finishingDate=null"
                              />
                            </div>
                          </label>
                          <flat-pickr
                            v-model="finishingDate"
                            class="form-control"
                            :placeholder="$t('contents.dateFinishing')"
                            :config="{
                              enableTime: true,
                              enableSeconds: true,
                              dateFormat: 'Y-m-d H:i:S',
                              minDate: publishingDate
                            }"
                          />
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                  </b-form>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
      <!-- Servidor externo -->
      <b-tab v-if="(typeStream === 'AWS' || typeStream === 'WOW') && language == base">
        <template #title>
          <feather-icon icon="CloudIcon" />
          <span>{{ $t('live.manageLive') }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="showState"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t('live.externalServer') }}</b-card-title>
              <div class="d-flex justify-content-end">
                <b-button
                  v-if="stateStream === 'STP'"
                  variant="success"
                  @click="startLive"
                >
                  {{ $t("live.startLive.name") }}
                </b-button>
                <b-button
                  v-if="stateStream === 'STR'"
                  variant="danger"
                  @click="stopLive"
                >
                  {{ $t("live.stopLive.name") }}
                </b-button>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row v-if="typeStream === 'AWS'">
                <b-col md="12">
                  <!-- form input -->
                  <b-form-group
                    class="mb-2 align-items-center"
                    :label="$t('live.url')"
                  >
                    <div class="d-flex">
                      <b-form-input
                        v-model="awsUrl"
                        autocomplete="new-password"
                        class="mr-1"
                        readonly
                      />
                      <!-- button -->
                      <b-button
                        v-clipboard:copy="awsUrl"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.copy") }}
                      </b-button>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="typeStream === 'AWS'">
                <b-col md="12">
                  <!-- form input -->
                  <b-form-group
                    class="mb-2 align-items-center"
                    :label="$t('live.password')"
                  >
                    <div class="d-flex">
                      <b-form-input
                        v-model="awsPassword"
                        autocomplete="new-password"
                        class="mr-1"
                        readonly
                      />
                      <!-- button -->
                      <b-button
                        v-clipboard:copy="awsPassword"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.copy") }}
                      </b-button>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="typeStream === 'AWS' && userData.isSuperuser">
                <b-col md="12">
                  <!-- form input -->
                  <b-form-group
                    class="mb-2 align-items-center"
                    :label="$t('live.distribution')"
                  >
                    <div class="d-flex">
                      <b-form-input
                        v-model="distributionCDNLiveId"
                        autocomplete="new-password"
                        class="mr-1"
                        readonly
                      />
                      <!-- button -->
                      <b-button
                        v-clipboard:copy="distributionCDNLiveId"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.copy") }}
                      </b-button>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="typeStream === 'AWS' && userData.isSuperuser">
                <b-col md="12">
                  <!-- form input -->
                  <b-form-group
                    class="mb-2 align-items-center"
                    :label="$t('live.tagDistribution')"
                  >
                    <div class="d-flex">
                      <b-form-input
                        v-model="distributionCDNLiveTag"
                        autocomplete="new-password"
                        class="mr-1"
                        readonly
                      />
                      <!-- button -->
                      <b-button
                        v-clipboard:copy="distributionCDNLiveTag"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="primary"
                      >
                        {{ $t("dataGeneric.copy") }}
                      </b-button>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
      <!-- SEO -->
      <b-tab>
        <template #title>
          <feather-icon icon="Edit2Icon" />
          <span>SEO</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="showSeo"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-header>
              <b-card-title>SEO</b-card-title>
              <div class="d-flex">
                <AIChatButton
                  ref="seo"
                  :view="'seo'"
                  :item="{ 'title': contentName, 'description': shortDescription, 'longDescription': contentDescription }"
                  :language="language"
                  :base="base"
                  :call-parent-method="getInfoToTranslate"
                  @updateData="getDataByAI"
                />
                <b-button
                  variant="success"
                  @click="updateSeo"
                >
                  {{
                    $t("dataGeneric.save")
                  }}
                </b-button>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  lg="12"
                  class="ml-2 mb-2"
                >
                  <b-form>
                    <b-form-group class="mb-2 mr-1">
                      <label
                        id="seoTitleLabel"
                        class="col-form-label pt-0"
                      >
                        {{ $t('title') }}
                      </label>
                      <div
                        ref="seoTitleDiv"
                        class=""
                      >
                        <b-form-input
                          v-model="seoTitle"
                          :placeholder="$t('title')"
                          class="field"
                          maxlength="80"
                        />
                      </div>
                    </b-form-group>

                    <b-form-group class="mb-2 mr-1">
                      <label
                        id="seoDescriptionLabel"
                        class="col-form-label pt-0"
                      >
                        {{ $t('seoDes') }}
                      </label>
                      <div
                        ref="seoDescriptionDiv"
                        class=""
                      >
                        <b-form-input
                          v-model="seoDescription"
                          :placeholder="$t('seoDes')"
                          maxlength="180"
                          class="field"
                        />
                      </div>
                    </b-form-group>
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('friendUrl')"
                    >
                      <b-form-input
                        v-model="friendlyUrl"
                        :placeholder="$t('friendUrl')"
                        maxlength="255"
                        class="mr-1"
                      />
                    </b-form-group>

                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('converFriendUrl') + ':'"
                    >
                      {{ friendlyUrlValue }}
                    </b-form-group>
                  </b-form>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
      <!-- CAPITULOS RELACIONADOS -->
      <b-tab v-if="language == base">
        <template #title>
          <feather-icon icon="PlayIcon" />
          <span>{{ $t('relatCha') }}</span>
        </template>
        <b-card>
          <b-card-header>
            <b-card-title>
              <span>
                {{ $t('relatCha') }}
                <b-badge class="ml-2 badge-light-warning">
                  <small class="text-warning">
                    <feather-icon
                      class="mr-25"
                      icon="AlertTriangleIcon"
                    />
                    {{ $t('indique') }}
                  </small>
                </b-badge>
              </span>
            </b-card-title>
            <b-button
              variant="success"
              @click="changePreviousContent"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-form>
              <b-form-row class="mb-2 align-items-center">
                <b-col>
                  <b-img
                    id="avatar"
                    rounded="lg"
                    :src="previousChapter != null
                      ? buildImageUrl(
                        previousChapter.imageUrl == ''
                          ? null
                          : previousChapter.imageUrl
                      )
                      : buildImageUrl(null)
                    "
                  />
                </b-col>
                <b-col md="8">
                  <label class="d-flex">
                    {{ $t('prevCha') }}
                    <feather-icon
                      class="text-danger cursor-pointer ml-2"
                      icon="XOctagonIcon"
                      size="15"
                      @click="deleteChapter(0)"
                    />
                  </label>
                  <b-form-input
                    id="previousChapter"
                    v-model="previousChapterName"
                    autocomplete="new-password"
                    :placeholder="$t('selectPrevCha')"
                    disabled
                  />
                </b-col>
                <b-col
                  md="1"
                  class="align-items-center"
                >
                  <b-button
                    variant="info"
                    class="mt-2"
                    @click="
                      categoryId == null
                        ? showMessage()
                        : $refs['cont-modal'].show()
                    "
                  >
                    {{ $t('Select') }}
                  </b-button>
                </b-col>
                <b-col
                  md="1"
                  class="align-items-center ml-1"
                >
                  <router-link
                    :to="{
                      name: 'contents-edit',
                      params: { id: previousChapterId }
                    }"
                    target="_blank"
                    :disabled="previousChapter != null ? false : true"
                  >
                    <b-button
                      class="mt-2"
                      variant="info"
                      :disabled="previousChapter != null ? false : true"
                    >
                      <span class="text-nowrap">
                        <feather-icon icon="EyeIcon" />
                        {{ $t('see') }}</span>
                    </b-button>
                  </router-link>
                </b-col>
              </b-form-row>
              <b-form-row class="mb-2 align-items-center">
                <b-col>
                  <b-img
                    id="avatar"
                    rounded="lg"
                    :src="nextChapter != null
                      ? buildImageUrl(
                        nextChapter.imageUrl == ''
                          ? null
                          : nextChapter.imageUrl
                      )
                      : buildImageUrl(null)
                    "
                  />
                </b-col>
                <b-col md="8">
                  <label class="d-flex">
                    {{ $t('nextChap') }}
                    <feather-icon
                      class="text-danger cursor-pointer ml-2"
                      icon="XOctagonIcon"
                      size="15"
                      @click="deleteChapter(1)"
                    />
                  </label>
                  <b-form-input
                    id="nextChapter"
                    v-model="nextChapterName"
                    autocomplete="new-password"
                    :placeholder="$t('nextChap')"
                    disabled
                  />
                </b-col>
                <b-col
                  md="1"
                  class="align-items-center"
                >
                  <router-link
                    :to="{
                      name: 'contents-edit',
                      params: { id: nextChapterID }
                    }"
                    :disabled="nextChapter != null ? false : true"
                    target="_blank"
                  >
                    <b-button
                      class="mt-2"
                      variant="info"
                      :disabled="nextChapter != null ? false : true"
                    >
                      <span class="text-nowrap">
                        <feather-icon icon="EyeIcon" />
                        {{ $t('see') }}</span>
                    </b-button>
                  </router-link>
                </b-col>
                <b-col
                  md="1"
                  class="align-items-center ml-1"
                >
                  <b-button class="d-none" />
                </b-col>
              </b-form-row>
            </b-form>
          </b-card-body>
        </b-card>
      </b-tab>
      <!-- CATEGORÍAS -->
      <b-tab v-if="language == base">
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>{{ $t('Categorias') }}</span>
        </template>
        <b-card v-if="!isMCH">
          <b-card-header>
            <b-card-title>{{ $t('firstCat') }}</b-card-title>
            <b-button
              variant="success"
              @click="updateCategoryData"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-form>
              <b-form-row class="mb-2 align-items-center">
                <b-col md="11">
                  <b-form-group>
                    <label>{{ $t('firstCat') }}
                      <feather-icon
                        v-if="categoryId != null"
                        class="cursor-pointer text-danger ml-50"
                        icon="XCircleIcon"
                        size="18"
                        @click="(categoryName = null, categoryId = null)"
                      />
                    </label>
                    <div @click="showModalCat('main')">
                      <b-form-input
                        id="category"
                        v-model="categoryName"
                        autocomplete="new-password"
                        :placeholder="$t('modal.category')"
                        disabled
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-button
                    variant="info"
                    @click="showModalCat('main')"
                  >
                    {{ $t('Select') }}
                  </b-button>
                </b-col>
              </b-form-row>
            </b-form>
          </b-card-body>
        </b-card>
        <b-card v-if="!isMCH">
          <b-card-header>
            <b-card-title>{{ $t('season') }}</b-card-title>
            <b-button
              variant="success"
              @click="updateCategoryData"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-card-header>
          <b-card-body>
            <b-form>
              <b-form-row class="mb-2 align-items-center">
                <b-col md="11">
                  <b-form-group>
                    <label>{{ $t('season') }}
                      <feather-icon
                        v-if="seasonCategoryId != null"
                        class="cursor-pointer text-danger ml-50"
                        icon="XCircleIcon"
                        size="18"
                        @click="(seasonCategoryName = null, seasonCategoryId = null)"
                      />
                    </label>
                    <div @click="showModalCat('season')">
                      <b-form-input
                        id="category"
                        v-model="seasonCategoryName"
                        autocomplete="new-password"
                        :placeholder="$t('modal.category')"
                        disabled
                      />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-button
                    variant="info"
                    @click="showModalCat('season')"
                  >
                    {{ $t('Select') }}
                  </b-button>
                </b-col>
              </b-form-row>
            </b-form>
          </b-card-body>
        </b-card>
        <b-card>
          <b-card-header>
            <b-card-title>{{ $t('fromCont') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-list-group>
              <b-list-group-item
                v-for="i in categories.edges"
                :key="i.node.id"
                class="d-flex justify-content-between cursor-pointer"
                @click.stop.prevent="specialCategory(i.node.type) ? catEspecial(i) : getElement(i.node.id, i.node.type)"
              >
                <div class="d-flex align-items-center ">
                  <b-img
                    :src="buildImageUrl(i.node.imageUrl)"
                    class="tableImage"
                  />

                  <div>
                    <small>{{ i.node.name }}</small>
                  </div>
                </div>
                <div class="align-content-center">
                  <b-button
                    variant="danger"
                    size="sm"
                    @click.stop.prevent="deallocationCategory(i.node)"
                  >
                    {{ $t('desvincular') }}
                  </b-button>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </b-tab>
      <!-- MOMENTOS -->
      <b-tab v-if="!isLive && !isMCH">
        <template #title>
          <feather-icon icon="MonitorIcon" />
          <span>{{ $t('momentos') }}</span>
        </template>
        <moments
          :stop="stop"
          lazy
          :language="language"
          :base="base"
        />
      </b-tab>
      <!-- PRERESOURCE -->
      <b-tab v-if="originalType == 'DIR'">
        <template #title>
          <feather-icon icon="MonitorIcon" />
          <span>{{ $t('prec') }}</span>
        </template>
        <live-intro
          :is-sponsor="isSponsor"
          lazy
        />
      </b-tab>
      <!-- AD CUE POINTS -->
      <b-tab v-if="language == base && hasAdCuePoints">
        <template #title>
          <feather-icon icon="AirplayIcon" />
          <span>Ad Cue Points</span>
        </template>
        <cue-ad-points
          :stop="stop"
          lazy
          :language="language"
          :base="base"
        />
      </b-tab>
      <!-- GEOBLOQUEO -->
      <b-tab v-if="geoipbloking && language == base">
        <template #title>
          <feather-icon icon="MapIcon" />
          <span>{{ $t('zones.title') }}</span>
        </template>
        <b-card>
          <geoblocking :type-element="'CON'" />
        </b-card>
      </b-tab>
      <b-tab
        v-if="language == base"
        lazy
      >
        <template #title>
          <feather-icon icon="DatabaseIcon" />
          <span>{{ $t('extendedFields') }}</span>
        </template>
        <json-data-view
          :json-data="extraData"
          :code="$route.params.id"
        />
      </b-tab>
    </b-tabs>

    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('editContent.recSelect')"
    >
      <div class="d-block text-center">
        <resources-selector
          :msg="tipo"
          :sponsor="isSponsor"
          :initial="true"
          :special="type"
          :audio="type == 'AUC' ? true : false"
          :contenido="$route.params.id"
          :recurso="contentResourceSelected"
          @data="seleccionarRecurso"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>

    <b-modal
      ref="cont-modal"
      size="xl"
      hide-footer
      :title="$t('modal.content')"
    >
      <div class="d-block text-center">
        <content-selector
          :sponsor="isSponsor"
          :code="$route.params.id"
          :main-category="categoryId"
          @data="SelectContentId"
          @url="SelectContentUrl"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="cat-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :especial="true"
          :sponsor="isSponsor"
          :columns="categorySelectorTable"
          @confirm-selection="CategorySelection"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>

import {
  BBadge,
  BFormSelectOption,
  BFormTextarea,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BAlert,
  BRow,
  VBTooltip,
  BCol,
  BCard,
  BImg,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormRow,
  BOverlay,
  BListGroup,
  BListGroupItem,
  BTabs,
  BLink,
  BTab,
} from 'bootstrap-vue'
import Vue from 'vue'
import { ValidationObserver } from 'vee-validate'
import * as constants from '@core/utils/constants'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import {
  messageError, camelToSnake, deactivateSlider, sortedContents, utf8ToB64, showToast, isEmpty, specialCategory, manageLive, checkFileType, removeRelation,
  limitCharacter, urlToFile,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { getQualityOptions } from '@/@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import geoblocking from '@/views/components/geoblocking.vue'
import videojs from 'video.js'
import LiveIntro from '@/views/contents/contents/tab/LiveIntro.vue'
import ResourcesSelector from '@/views/common/ResourcesSelector.vue'
import CategorySelector from '@/views/common/CategorySelector.vue'
import ContentSelector from '@/views/common/ContentSelector.vue'
import TranscodingInfo from '@/views/components/TranscodingInfo.vue'
import Moments from '@/views/contents/contents/Moments.vue'
import CueAdPoints from '@/views/contents/contents/CueAdPoints.vue'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
// import "flatpickr/dist/themes/material_blue.css";
import configCategoryType from '@core/utils/categoryType'
import vSelect from 'vue-select'
import configContentType from '@core/utils/contentType'
import ImageElementVue from '@/views/components/ImageElement.vue'
import AIChatButton from '@/views/common/AIChatButton.vue'
import JsonDataView from '../JsonDataView.vue'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    flatPickr,
    LiveIntro,
    BImg,
    BBadge,
    ResourcesSelector,
    BFormSelectOption,
    BFormTextarea,
    ContentSelector,
    Moments,
    vSelect,
    CueAdPoints,
    BCardHeader,
    AIChatButton,
    JsonDataView,
    BCardTitle,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BAlert,
    CategorySelector,
    BForm,
    geoblocking,
    ImageElementVue,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BOverlay,
    ValidationObserver,
    TranscodingInfo,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      extraData: null,
      hasAdCuePoints: false,
      isEmpty,
      contentSlide: 0,
      viewName: this.$t('editContent.title'),
      viewDetail: this.$t('editContent.subtitle'),
      typeCat: null,
      hasSubtitle: false,
      quality: 'No_Quality',
      qualityOptions: getQualityOptions(),
      imageDataUrl: '',
      imageDataMobUrl: '',
      imageDataAltUrl: '',
      defaultLanguage: '',
      imageDataTitUrl: '',
      imageDataBckUrl: '',
      backgroundMobileUrl: '',
      imageDataTrUrl: '',
      isSponsor: false,
      userData: getUserData(),
      specialCategory,
      duration: 0,
      indexTabs: null,
      previousChapter: null,
      nextChapter: null,
      nextChapterID: null,
      nextChapterName: null,
      categoryId: null,
      imageCat: null,
      allClasification: [],
      selected: [],
      idTags: [],
      originalTags: [],
      option: [],
      allGenre: [{ value: null, text: this.$t('contents.genreContent') }],
      selectedGenre: null,
      selectedClasification: null,
      showSeo: false,
      video: null,
      fileUrl: null,
      imageBlob: null,
      linkTexto:
        "<iframe width='560' height='315' src='' frameBorder='0' allowfullscreen></iframe>",

      seoTitle: null,
      seoDescription: null,
      fromRouteData: null,
      createdAt: null,
      modifiedAt: null,
      required,
      contentResourceSelected: null,
      totalResource: null,
      emisionDate: null,
      oldEmisionDate: null,
      oldContentName: null,
      tipo: 'VID',
      contentBadge: null,
      type: 'NOR',
      originalType: '',
      isMCH: false,
      typeOptions: configContentType(true, false),
      contentStatus: 'RED',
      status: [
        { value: 'RED', text: `🔴 ${this.$t('code.desactivado')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      categorySelectorTable: [
        { key: 'image', label: this.$t('zones.category') },
        { key: 'name', label: this.$t('dataGeneric.name') },
        { key: 'type', label: this.$t('type') },
      ],
      isDownload: false,
      showContentsCategories: false,
      isFreemium: false,
      hasDvr: false,
      publishingDate: null,
      finishingDate: null,
      imageData: null,
      imageDataMob: null,
      imageDataTr: null,
      imageDataAlt: null,
      imageDataTit: null,
      imageDataBck: null,
      contentName: '',
      contentDescription: '',
      comment: '',
      technicalDetails: '',
      contentPremium: false,
      contentAds: false,
      languageSelected: null,
      languageOption: [],
      imageContentSelected: false,
      imageAlternativeSelected: false,
      mobileImageContentSelected: false,
      backgroundContentSelected: false,
      titleImageContentSelected: false,
      trailerContentContentSelected: false,
      catContentId: [],
      shortDescription: null,
      isActive: false,
      active: false,
      client: '',
      gnBut: false,
      resourceName: null,
      videotype: null,
      inputStream: null,
      passwordStream: null,
      canvas: null,
      origResource: null,
      reference: '',
      optaId: '',
      contentsResource: null,
      mediaOriginSelected: 'BUK',
      mediaOriginSelectedDB: 'BUK',
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      portadaSelect: 'PORT',
      friendlyUrl: null,
      friendlyUrlValue: null,
      categories: [],
      portadaType: [
        { value: 'PORT', text: this.$t('Portada') },
        { value: 'ALT', text: this.$t('alt-j') },
        { value: 'FOND', text: this.$t('branding.label3') },
        { value: 'MOV', text: this.$t('Movil') },
      ],
      showData: false,
      showDesign: false,
      showState: false,
      showResource: false,
      contentResourcesListForUpdate: [],
      labelStatus: 'RED',
      hour: 0,
      minute: 0,
      second: 0,
      tHour: '00',
      tMinute: '00',
      tSecond: '00',
      backgroundMobile: null,
      disabledAdd: false,
      disabledDownload: false,
      categoryName: null,
      seasonCategoryName: null,
      seasonCategoryId: null,
      previousChapterId: null,
      previousChapterName: null,
      backgroundMobileSelected: false,
      categoryTypes: configCategoryType(true, false, false, false),
      awsUrl: '',
      awsPassword: '',
      distributionCDNLiveId: '',
      distributionCDNLiveTag: '',
      typeStream: null,
      stateStream: null,
      showRestrictionsOne: false,
      showRestrictionsTwo: false,
      isDisplay: false,
      mostrarDatos: false,
      nDisplay: 0,
      maxViews: 0,
      stop: 0,
      badges: [],
      isLive: false,
      isPodcast: false,
      disabledBuy: false,
      targetWebActive: false,
      targetHbbtvActive: false,
      targetMobileActive: false,
      targetSmarttvActive: false,
      selectAll: false,
      platformAllowTranscoding: false,
      couldTranscode: false,
      showMsgStateTranscode: false,
      transcodingState: 'NOT',
      idTranscoding: false,
      geoipbloking: false,
      jsonError: '',
      platformMode: '',
    }
  },
  watch: {

    targetWebActive() {
      if (!this.targetWebActive) {
        this.selectAll = false
      }
      if (this.targetWebActive && this.targetHbbtvActive && this.targetMobileActive && this.targetSmarttvActive) this.selectAll = true
    },
    targetHbbtvActive() {
      if (!this.targetHbbtvActive) {
        this.selectAll = false
      }
      if (this.targetWebActive && this.targetHbbtvActive && this.targetMobileActive && this.targetSmarttvActive) this.selectAll = true
    },
    targetMobileActive() {
      if (!this.targetMobileActive) {
        this.selectAll = false
      }
      if (this.targetWebActive && this.targetHbbtvActive && this.targetMobileActive && this.targetSmarttvActive) this.selectAll = true
    },
    targetSmarttvActive() {
      if (!this.targetSmarttvActive) {
        this.selectAll = false
      }
      if (this.targetWebActive && this.targetHbbtvActive && this.targetMobileActive && this.targetSmarttvActive) this.selectAll = true
    },
    indexTabs() {
      if (this.video) {
        this.video.pause()
        this.stop += 1
      }
    },
    duration() {
      function checkNumberType(value) {
        if (Number.isFinite(value)) {
          return true
        }
        return false
      }
      try {
        this.duration = checkNumberType(this.duration) ? this.duration.toFixed(2) : this.duration
      } catch (error) {
        console.log(error)
      }
    },
    friendlyUrl() {
      this.friendlyUrlValue = this.friendlyUrl
        // eslint-disable-next-line no-useless-escape
        .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ')
        .toLowerCase()

      // Corta los espacios al inicio y al final del this.friendlyUrling
      this.friendlyUrlValue = this.friendlyUrlValue.replace(/^\s+|\s+$/gm, '')

      // Reemplaza el espacio con guión
      this.friendlyUrlValue = this.friendlyUrlValue.replace(/\s+/g, '-')
    },
    tHour() {
      if (this.tHour.length < 2) {
        this.tHour = parseInt(`0${this.tHour}`, 10)
      }
    },
    tMinute() {
      if (this.tHour === this.hour) {
        if (this.tMinute > this.minute) {
          this.tMinute = this.minute
        }
      }
      if (this.tMinute == null) {
        this.tMinute = 0
      }
    },
    tSecond() {
      if (this.tMinute === this.minute) {
        if (this.tSecond > this.second) {
          this.tSecond = this.second
        }
      }
      if (this.tSecond == null) {
        this.tSecond = 0
      }
    },
    publishingDate() {
      if (this.publishingDate > this.finishingDate) {
        this.finishingDate = null
      }
    },

  },
  mounted() {
    this.hasAdCuePoints = this.userData.profile.client.hasAdCuePoints
    if (this.$route.name === 'live-content-edit') this.isLive = true
    if (this.$route.name === 'podcast-content-edit') this.isPodcast = true

    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = this.userData.groups.edges[0].node.name === constants.sponsor
    }

    this.geoipbloking = this.userData?.profile?.client?.geoipbloking

    this.getDefaultLanguage()
    this.fillParemeterClient()
    this.getTags()
    this.getContentInfo()
    this.allLanguages()
    this.allResource()
    this.allcontentResource()
    this.clasification()
    this.genre()
    this.allBadges()
    this.getPlatformAllowTranscoding()
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },
  methods: {
    fillParemeterClient() {
      try {
        this.disabledBuy = !this.userData.profile.client.isBuy
        this.disabledAdd = !this.userData.profile.client.isAds
        this.disabledDownload = !this.userData.profile.client.isDownload
      } catch (error) {
        console.log(error)
      }
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.tCopy'),
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.errorCopy'),
          icon: 'BellIcon',
        },
      })
    },
    shouldDisableOptionTypeContent() {
      // Lógica de deshabilitar opciones
      /*  const isNotPOD = this.originalType !== 'POD';
       const isNotAUC = this.originalType !== 'AUC'; */
      const isAWS = this.typeStream === 'AWS'
      const isWOW = this.typeStream === 'WOW'

      return (isAWS || isWOW)
      // return ((isNotPOD && isNotAUC) && t === 'POD') || (isAWS || isWOW);
      // return  ((this.originalType != 'POD' && this.originalType != 'AUC') && t === 'POD') || (this.typeStream === 'AWS' || this.typeStream === 'WOW')
    },
    allBadges() {
      axios
        .post('', {
          query: `
            {
    allBadge(client:"${this.userData.profile.client.id}")
          {
          edges
              {
                  node
                  {
                    id
                    name
                    isActive
                    backgroundColor
                    textColor
                    image
                  }
                }
              }
            }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.badges = []

          res.data.data.allBadge.edges.forEach(element => {
            this.badges.push({ text: element.node.name, value: element.node.id })
          })
        })
        .catch(() => {
        })
    },

    getPlatformAllowTranscoding() {
      this.platformAllowTranscoding = this.userData.profile.client.isTranscoding
    },

    lengthHour() {
      if (this.tHour.length === 2) {
        document.getElementById('tMinute').focus()
      }
    },
    lengthMinute() {
      if (this.tMinute.length === 2) {
        document.getElementById('tSecond').focus()
      }
    },
    lengthSecond() {
      if (this.tSecond.length === 2) {
        this.timePick()
      }
    },
    modalTags() {
      const headers = {
        'Accept-Language': camelToSnake(this.language),
        'Content-Language': camelToSnake(this.language),
      }
      if (this.selected.length > this.idTags.length) {
        let id = ''
        axios
          .post('', {
            query: `
          {
            allTags(name:"${this.selected[this.selected.length - 1]}") {
              edges {
                node {
                  id
                  name
                  createdAt

                }
              }
            }
          }
        `,
          }, { headers })
          .then(result => {
            messageError(result, this)

            let crear = true
            result.data.data.allTags.edges.forEach(element => {
              if (
                element.node.name.toLowerCase()
                === this.selected[this.selected.length - 1].toLowerCase()
              ) {
                id = element.node.id
                crear = false
              }
            })

            if (crear) {
              /* AQUÍ CREA EL TAG */

              axios
                .post('', {
                  query: `
              mutation{
                createTag(input:{name:"${this.selected[this.selected.length - 1]
}"}){
                    tag{
                        id
                        name
                    }
                }
            }
            `,
                }, { headers })
                .then(res => {
                  messageError(res, this)

                  this.idTags.push(res.data.data.createTag.tag.id)
                })
                .catch(() => { })
            } else {
              this.idTags.push(id)
            }
          })
          .catch(() => { })
      } else {
        this.originalTags.forEach((a, index) => {
          let quitar = true
          this.selected.forEach(element => {
            if (element.toLowerCase() === a.toLowerCase()) {
              quitar = false
            }
          })
          if (quitar) {
            this.idTags.splice(index, 1)
            this.originalTags.splice(index, 1)
          }
        })
      }
    },
    clickAiImage(data) {
      this.$refs.image.openChatAI(data)
    },
    getTags() {
      axios
        .post('', {
          query: `
          {
            allTags {
              edges {
                node {
                  id
                  name
                  createdAt

                }
              }
            }
          }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allTags.edges.forEach(element => {
            this.option.push({
              title: element.node.name,
              value: element.node.id,
            })
          })
        })
        .catch(() => { })
    },

    seleccionarRecurso(data) {
      this.hideModal()
      const [resource, name] = data
      this.resourceName = name
      this.contentResourceSelected = resource

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('code.rec'),
          icon: 'BellIcon',
          text: this.$t('code.recSel', { resourceName: this.resourceName }),
          variant: 'success',
        },
      })
      this.updateResourceInitial()
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
      this.$refs['cat-modal'].hide()
      this.$refs['cont-modal'].hide()
    },
    allLanguages() {
      axios
        .post('', {
          query: `
        query{
            allLanguages{
              edges{
                node{
                  id
                  language
                   locale
                }
              }
            }
          }
        `,
        })
        .then(response => {
          messageError(response, this)

          this.languageOption = response.data.data.allLanguages.edges
        })
    },
    allResource() {
      axios
        .post('', {
          query: `
        query{
              allResources(client:"${this.userData.profile.client.id}"
              ${this.isSponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}
              ){
                edges{
                  node{
                  id
                  name
                  type
                  file{
                    name
                    fileUrl
                  }
                  }

                }
              }
          }
        `,
        })
        .then(response => {
          messageError(response, this)

          this.totalResource = response.data.data.allResources.edges
        })
    },

    allcontentResource() {
      const { id } = this.$route.params
      const encondedId = utf8ToB64(`content:${id}`)
      this.showResource = true
      const headers = {
        'Accept-Language': camelToSnake(this.language),
        'Content-Language': camelToSnake(this.language),
      }
      axios
        .post('', {
          query: `
         query{
          contentsResource(content:"${encondedId}",isInitial:true){
            edges{
              node{
                isInitial
                resource{
                  name
                  videotype
                  inputStream
                  passwordStream
                  id
                  directUrl
                  bucketUrl
                  urlInputMedialiveAws
                  idDistributionCdnLiveAws
                  typeStream
                  stateStream
                  file{
                    fileUrl
                  }
                  transcodingState
                  idTranscoding
                }
                content
              }
            }
          }
          }
        `,
        }, { headers })
        .then(response => {
          messageError(response, this)

          try {
            const [contentRes] = response.data.data.contentsResource.edges
            this.contentsResource = contentRes
            this.contentResourceSelected = this.contentsResource.node.resource.id

            this.resourceName = this.contentsResource.node.resource.name
            this.videotype = this.contentsResource.node.resource.videotype
            this.passwordStream = this.contentsResource.node.resource.passwordStream
            this.inputStream = this.contentsResource.node.resource.inputStream
            try {
              this.fileUrl = this.contentsResource.node.resource.file.fileUrl
            } catch (error) {
              this.fileUrl = this.contentsResource.node.resource.directUrl
            }
            this.transcodingState = this.contentsResource.node.resource.transcodingState
            this.idTranscoding = this.contentsResource.node.resource.idTranscoding
            checkFileType(this.fileUrl)
              .then(async mimeType => {
                if (this.platformAllowTranscoding && (this.videotype === 'BUK' || this.videotype === 'URL') && ['MP3', 'MP4', 'MOV'].includes(mimeType)) {
                  this.showMsgStateTranscode = true
                  const mediaAssets = await this.getMediaAsset(utf8ToB64(`id:${this.contentResourceSelected}`))
                  // Se ha tenido en cuenta de que si hay algun mediasset transcodificado, no se pueda transcodificar
                  const mediaAssetsTranscoding = mediaAssets.filter(edge => ['HLS', 'DASH'].includes(edge.node.type))
                  if (mediaAssetsTranscoding.length === 0 && ['NOT', 'ERT'].includes(this.transcodingState)) {
                    this.couldTranscode = true
                  }
                }
              }).catch(error => {
                messageError(error, this)
              })
            this.origResource = this.contentResourceSelected
            this.typeStream = this.contentsResource.node.resource.typeStream
            this.stateStream = this.contentsResource.node.resource.stateStream
            const urlInputMediaLiveAws = this.contentsResource.node.resource.urlInputMedialiveAws
            this.distributionCDNLiveId = this.contentsResource.node.resource.idDistributionCdnLiveAws
            this.distributionCDNLiveId = this.contentsResource.node.resource.idDistributionCdnLiveAws
            const clientName = this.userData.profile.client.name
            this.distributionCDNLiveTag = `CDN_${clientName}_${this.contentResourceSelected}`
            if (this.typeStream === 'AWS') {
              const urlParts = urlInputMediaLiveAws.split('/')
              this.awsUrl = `${urlParts.slice(0, -1).join('/')}/`
              const [urlSlice] = urlParts.slice(-1)
              this.awsPassword = urlSlice
            }
          } catch (error) { console.log(error) }

          this.iframeField()

          if (this.video) {
            if (this.fileUrl != null && this.fileUrl.length !== 0) {
              if (this.fileUrl.includes('m3u8')) {
                this.video.src({
                  type: 'application/x-mpegURL',
                  src: this.fileUrl,
                })
              } else {
                this.video.src({
                  type: 'video/mp4',
                  src: this.fileUrl,
                })
              }
            }
          }
          this.showResource = false
        }).catch(() => {
          this.showResource = false
        })
    },

    async getMediaAsset(resourceId) {
      const query = `
      {
          allMediaasset(resource:"${resourceId}") {
            totalCount
            edges {
              node {
                id
                type
              }
            }
          }  
      }`

      try {
        const response = await axios.post('', { query })
        return response.data.data.allMediaasset.edges
      } catch (error) {
        messageError(error)
      }
      return ''
    },
    deallocationCategory(node) {
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('removeCat'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showData = true
          removeRelation(node.id, [this.$route.params.id]).then(res => {
            messageError(res, this)
            showToast(this.$t('success'), 1, this)
            this.getContentInfo()
            this.showData = false
          }).catch(err => {
            console.log(err)
          })
        }
      })
        .catch(err => { console.log(err) })
    },
    fechasSplit(value) {
      if (!value || typeof value !== 'string') {
        return ''
      }

      try {
        const dateObj = new Date(value)

        const day = String(dateObj.getDate()).padStart(2, '0')
        const month = String(dateObj.getMonth() + 1).padStart(2, '0') // Months are 0-based
        const year = dateObj.getFullYear()
        const hours = String(dateObj.getHours()).padStart(2, '0')
        const minutes = String(dateObj.getMinutes()).padStart(2, '0')
        const seconds = String(dateObj.getSeconds()).padStart(2, '0')

        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
      } catch (error) {
        return ''
      }
    },
    fechaFormato(value) {
      const fecha = new Date(value)
      return value != null ? fecha : value
    },
    getContentInfo() {
      const { id } = this.$route.params
      axios
        .post('', {
          query: `
         query{
            allContents(id:"${id}"){
              edges{
                node{
                  creator {
                    id
                  }
                 contentResources{
                    edges {
                      node {
                        id
                        content
                        resource {
                          id
                        }
                      }
                    }
                  }
                id
                extraData
                contentSlide {
                  totalCount
                  edges {
                    node {
                      id
                    }
                  }
                }
                targetWebActive   
                targetHbbtvActive       
                targetMobileActive
                targetSmarttvActive
                technicalDetails(lang:"${this.language}")
                friendlyUrl(lang:"${this.language}")
                comment
                shortDescription(lang:"${this.language}")
                name(lang:"${this.language}")
                description(lang:"${this.language}")
                image
                imageUrl(lang:"${this.language}")
                alternativeImage
                backgroundMobile
                backgroundMobileUrl(lang:"${this.language}")
                alternativeImageUrl(lang:"${this.language}")
                titleImage
                titleImageUrl(lang:"${this.language}")
                imageMobile
                imageMobileUrl(lang:"${this.language}")
                seoTitle(lang:"${this.language}")
                seoDescription(lang:"${this.language}")
                background
                backgroundUrl(lang:"${this.language}")
                trailer
                trailerUrl(lang:"${this.language}")
                hasSubtitle
                quality
                 maxViews
                 nDisplay
                 isDisplay
                 isDownload
                 showContentsCategories
                 isFreemium
                 hasDvr
                 type
                 createdAt
                 modifiedAt
                 nextChapter {
                    id
                    name
                    mediaLocation
                      image
                      imageUrl
                  }
                  previousChapter {
                    id
                    name
                    mediaLocation
                    image
                    imageUrl
                    nextChapter {
                      id
                      name
                    }
                    previousChapter {
                      id
                      name
                    }
                  }
                 genre {
                    id
                    name
                  }
                  badges {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                  clasification {
                    id
                    type
                  }
                  tags {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                 mediaLocation
                  emisionDate
                  reference
                  optaId
                   mainCategory {
                    id
                    name
                    image
                    imageUrl
                  }
                  seasonCategory{
                    id
                    name
                    image
                    imageUrl
                  }
                 
                  isAds
                  isActive
                  isPremium
                  publishDate
                  expirationDate
                  state
                  language{
                    id
                    language
                    locale
                  }
                  inapppurchase{
                    name
                  }
                  duration
                  resources{
                    edges{
                      node{
                        name
                        id
                      }
                    }
                  }
                  client{
                    name
                    id
                  }
                  categories{
                    edges
                    {
                      node
                      {
                        id
                        name
                        imageUrl
                        type
                        orderType
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)
          const response = res.data.data.allContents.edges[0]

          if (response) {
            this.removeFillFieldClasses()
            const { node } = response
            // Sponsor role can access every content, but cannot delete it. Uncomment it to restrict access again
            /* let authorized = false
            if (this.isSponsor) {
              if (node.creator !== null) {
                if (node.creator.id === this.userData.id) authorized = true
              }
              if (!authorized) {
                this.$router.push({ name: 'misc-not-authorized' })
              }
            } */
            if (isEmpty(this.jsonError)) {
              this.extraData = node.extraData
            }
            this.targetWebActive = node.targetWebActive
            this.targetHbbtvActive = node.targetHbbtvActive
            this.targetMobileActive = node.targetMobileActive
            this.targetSmarttvActive = node.targetSmarttvActive
            if (this.targetWebActive && this.targetHbbtvActive && this.targetMobileActive && this.targetSmarttvActive) this.selectAll = true
            this.contentResourcesListForUpdate = node.contentResources.edges
              .length
              ? node.contentResources.edges.map(item => item.node.resource.id)
              : []
            this.selected = []
            this.idTags = []
            this.originalTags = []
            node.tags.edges.forEach(element => {
              this.selected.push(element.node.name)
              this.idTags.push(element.node.id)
              this.originalTags.push(element.node.name)
            })
            this.isActive = node.isActive
            this.active = node.isActive
            this.contentSlide = node.contentSlide.totalCount
            this.shortDescription = node.shortDescription
            this.maxViews = node.maxViews
            this.isDisplay = node.isDisplay
            this.nDisplay = node.nDisplay
            this.friendlyUrl = node.friendlyUrl
            this.contentStatus = node.state
            this.labelStatus = node.state
            this.contentName = node.name
            this.oldContentName = node.name
            this.contentDescription = node.description
            this.comment = node.comment
            this.technicalDetails = node.technicalDetails
            this.contentPremium = node.isPremium
            this.contentAds = node.isAds
            this.mediaOriginSelected = node.mediaLocation
            this.mediaOriginSelectedDB = node.mediaLocation
            this.hasSubtitle = node.hasSubtitle
            this.quality = node.quality != null ? node.quality : 'No_Quality'
            this.seoTitle = node.seoTitle
            this.seoDescription = node.seoDescription
            this.isDownload = node.isDownload
            this.showContentsCategories = node.showContentsCategories
            this.isFreemium = node.isFreemium
            this.hasDvr = node.hasDvr
            this.type = node.type
            this.duration = node.duration / 60
            this.originalType = node.type
            this.isMCH = this.originalType === 'MCH'
            if (this.originalType === 'POD' || this.originalType === 'AUC') {
              this.isPodcast = true
            }
            if (this.originalType === 'DIR') {
              this.isLive = true
            } else {
              this.isLive = false
            }
            this.categories = node.categories
            this.previousChapter = node.previousChapter
            this.previousChapterId = node.previousChapter?.id
            this.previousChapterName = node.previousChapter?.name
            this.nextChapter = node.nextChapter
            this.nextChapterID = node.nextChapter?.id
            this.nextChapterName = node.nextChapter?.name

            this.categoryName = node.mainCategory?.name
            this.categoryId = node.mainCategory?.id

            this.seasonCategoryName = node.seasonCategory?.name
            this.seasonCategoryId = node.seasonCategory?.id

            this.createdAt = node.createdAt
            this.modifiedAt = node.modifiedAt
            try {
              this.publishingDate = this.fechaFormato(node.publishDate)
              this.finishingDate = this.fechaFormato(node.expirationDate)
              this.emisionDate = this.fechaFormato(node.emisionDate)
              this.oldEmisionDate = this.fechaFormato(node.emisionDate)
            } catch (error) {
              this.emisionDate = null
              this.publishingDate = null
              this.finishingDate = null
            }

            try {
              this.languageSelected = node.language.id
            } catch (error) {
              this.languageSelected = null
            }
            if (this.mediaOriginSelected === 'DIR') {
              this.imageDataUrl = node.imageUrl
              this.imageDataAltUrl = node.alternativeImageUrl
              this.imageDataTitUrl = node.titleImageUrl
              this.imageDataMobUrl = node.imageMobileUrl
              this.imageDataBckUrl = node.backgroundUrl
              this.backgroundMobileUrl = node.backgroundMobileUrl
              this.imageDataTrUrl = node.trailerUrl
            } else {
              this.imageContentSelected = node.imageUrl
              this.imageAlternativeSelected = node.alternativeImageUrl
              this.mobileImageContentSelected = node.imageMobileUrl
              this.backgroundContentSelected = node.backgroundUrl
              this.backgroundMobileSelected = node.backgroundMobileUrl
              this.titleImageContentSelected = node.titleImageUrl
              this.trailerContentContentSelected = node.trailerUrl
            }
            try {
              this.selectedGenre = node.genre.id
            } catch (error) {
              this.selectedGenre = null
            }
            try {
              this.selectedClasification = node.clasification.id
            } catch (error) {
              this.selectedClasification = null
            }
            if (node.badges.edges.length !== 0) {
              this.contentBadge = node.badges.edges[0].node.id
            }
            this.reference = node.reference
            this.optaId = node.optaId

            /* if (!authorized && this.isSponsor) this.mostrarDatos = false
            else {
              this.mostrarDatos = true
            } */
            this.mostrarDatos = true // Sponsor role can access every content, but cannot delete it. Uncomment it to restrict access again

            this.$emit('return', {
              name: this.contentName, viewName: this.viewName, viewDetail: this.viewDetail, interactivities: true, state: this.labelStatus,
            })
          } else {
            this.$swal({
              title: this.$t('warning'),
              text: this.$t('imSorry'),
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: this.$t('dataGeneric.goBack'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.$router.push({ name: 'dashboard' })
              }
            })
              .catch(err => { console.log(err) })
          }
        })
    },
    updateContentData(event) {
      const headers = {
        'Accept-Language': camelToSnake(this.language),
        'Content-Language': camelToSnake(this.language),
      }
      this.showData = true
      event.preventDefault()
      const { id } = this.$route.params
      let objectVariables = []
      objectVariables = [
        { title: `name${this.language}`, value: limitCharacter(this.contentName, 150), type: 'String' },
        { title: `description${this.language}`, value: this.contentDescription, type: 'String' },
        { title: `technicalDetails${this.language}`, value: this.technicalDetails, type: 'String' },
        { title: `shortDescription${this.language}`, value: limitCharacter(this.shortDescription, 180), type: 'String' },
      ]
      if (this.language === this.base) {
        objectVariables.push(
          { title: 'comment', value: this.comment, type: 'String' },
          { title: 'badges', value: this.contentBadge ? [this.contentBadge] : [], type: '[ID]' },
          { title: 'quality', value: this.quality !== 'No_Quality' ? this.quality : null, type: 'ContentQualityInput' },
          { title: 'hasSubtitle', value: this.hasSubtitle, type: 'Boolean' },
          { title: 'isAds', value: this.contentAds, type: 'Boolean' },
          { title: 'isDownload', value: this.isDownload, type: 'Boolean' },
          { title: 'showContentsCategories', value: this.showContentsCategories, type: 'Boolean' },
          { title: 'isFreemium', value: this.isFreemium, type: 'Boolean' },
          { title: 'hasDvr', value: this.hasDvr, type: 'Boolean' },
          { title: 'isPremium', value: this.contentPremium, type: 'Boolean' },
          { title: 'genre', value: this.selectedGenre, type: 'ID' },
          { title: 'clasification', value: this.selectedClasification, type: 'ID' },
          { title: 'reference', value: this.reference, type: 'String' },
          { title: 'optaId', value: this.optaId, type: 'Int' },
          { title: 'emisionDate', value: this.fechaFormato(this.emisionDate), type: 'DateTime' },
          { title: 'type', value: this.type, type: 'ContentTypeInput' },
          { title: 'duration', value: this.duration * 60, type: 'Int' },
        )
      }
      const variables = {}
      let mutation = 'mutation('
      let query = `{updateContents(id:"${id}",input:{\n
        tags: [${this.idTags}]\n`
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        content{
                id
                name
                comment
                technicalDetails
                isPremium
                isAds
                language{
                  language
                }
                 tags {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              mainCategory {
                id
                name
                image
                imageUrl
              }

            }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        }, { headers })
        .then(res => {
          messageError(res, this)
          showToast(
            this.$t('code.cat', {
              data: res.data.data.updateContents.content.name,
            }),
            1, this,
          )
          const fecha1 = new Date(this.emisionDate)
          const fecha2 = new Date(this.oldEmisionDate)

          if ((fecha1.getTime() !== fecha2.getTime()) || (this.contentName !== this.oldContentName)) {
            this.reorderCategories((this.contentName !== this.oldContentName))
          }
          this.getContentInfo()
          this.showData = false
        })
        .catch(error => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.showData = false
          console.log(error)
        })
    },
    reorderCategories(orderBool) {
      let reorder = false
      this.categories.edges.forEach(category => {
        if (category.node.orderType !== 'MAN') {
          if (orderBool && category.node.orderType === 'ALF') {
            reorder = true
            sortedContents(category.node.id, category.node.orderType).then(() => {
            }).catch(err => {
              console.log(err)
            })
          } else if (!orderBool && category.node.orderType !== 'ALF') {
            reorder = true
            sortedContents(category.node.id, category.node.orderType).then(() => {
            }).catch(err => {
              console.log(err)
            })
          }
        }
      })
      if (reorder) {
        this.$swal({
          title: this.$t('CategoriesUpd'),
          text: this.$t('cateryReorder'),
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: this.$t('next'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(() => {

        })
      }
    },
    updateCategoryData(event) {
      this.showData = true
      event.preventDefault()
      const { id } = this.$route.params

      axios
        .post('', {
          variables: {
            mainCategory: this.categoryId,
            seasonCategory: this.seasonCategoryId,
          },
          query: `
            mutation($mainCategory: ID,$seasonCategory: ID){
            updateContents(id:"${id}", input:{
              mainCategory: $mainCategory,
              seasonCategory: $seasonCategory,

              }){
              content{
                id
                name
                comment
                technicalDetails
                isPremium
                isAds
                language{
                  language
                }
                 tags {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                 mainCategory {
                    id
                    name
                    image
                    imageUrl
                  }

              }

            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('code.cat', {
              data: res.data.data.updateContents.content.name,
            }),
            1, this,
          )

          this.getContentInfo()

          this.showData = false
        })
        .catch(error => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.showData = false
          console.log(error)
        })
    },
    iframeField() {
      const host = 'https://embed.interactvty.com/'

      const url = `${host}?content=${this.$route.params.id}`

      this.linkTexto = `<iframe width='560' height='315' src='${url
      }' frameBorder='0' allowfullscreen></iframe>`
    },

    updateSeo(toast = true) {
      const headers = {
        'Accept-Language': camelToSnake(this.language),
        'Content-Language': camelToSnake(this.language),
      }
      this.showSeo = true
      const { id } = this.$route.params
      let objectVariables = []
      objectVariables = [
        { title: `seoTitle${this.language}`, value: limitCharacter(this.seoTitle, 80), type: 'String' },
        { title: `seoDescription${this.language}`, value: limitCharacter(this.seoDescription, 180), type: 'String' },
        { title: `friendlyUrl${this.language}`, value: limitCharacter(this.friendlyUrlValue, 255), type: 'String' },
      ]

      const variables = {}
      let mutation = 'mutation('
      let query = `{updateContents(id:"${id}",input:{\n
        `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        content{
            id
            name
            
          }
        }
      }`
      query = mutation + query
      axios
        .post('', {
          variables,
          query,
        }, { headers })
        .then(res => {
          messageError(res, this)
          if (toast) {
            showToast(
              this.$t('code.cat', {
                data: res.data.data.updateContents.content.name,
              }),
              1, this,
            )
          }
          this.getContentInfo()

          this.showSeo = false
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.showSeo = false
        })
    },
    updateResourceInitial() {
      this.showResource = true

      const { id } = this.$route.params

      let query = `
       {
        allContents(id:"${id}") {
          edges {
            node {
              id
              name
              resources {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              contentResources {
                edges {
                  node {
                    id
                    isInitial
                    resource {
                      id
                      name
                    }
                  }
                }
              }              
            }
          }
        }
        }
      `

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)
          const contentResources = res.data.data.allContents.edges[0].node.contentResources.edges

          if (contentResources.length === 0) {
            query = 'mutation{'
            query += `inicial: createContentsResource(input:{content:"${id}",resource:"${this.contentResourceSelected}",isInitial:true}) {
                 contentResource2{
                id
              isInitial
                content
                resource{
                  id
                  name
                  file{
                    mediaDuration
                  }
                }
              }
              }
              `

            query += '}'

            axios
              .post('', {
                query,
              })
              .then(result => {
                messageError(result, this)

                try {
                  const duration = result.data.data.inicial.contentResource2.resource.file
                    .mediaDuration
                  axios
                    .post('', {
                      query: `mutation{
                        updateContents(id:"${id}",input:{duration:${duration || 0}}){
                          content{
                            id
                            name
                            duration
                          }
                        }
                      }`,
                    })
                    .then(r => {
                      messageError(r, this)

                      this.showResource = false
                    })
                    .catch(() => {
                      this.showResource = false
                    })
                } catch (error) {
                  this.showResource = false
                }
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            let queryDel = 'mutation{'
            contentResources.forEach((element, i) => {
              queryDel += `a${i}:deleteContentsResource(id:"${element.node.id}"){
              found
                deletedId
              }`
            })
            queryDel += '}'

            axios
              .post('', {
                query: queryDel,
              })
              .then(result => {
                messageError(result, this)

                query = 'mutation{'
                query += `inicial: createContentsResource(input:{content:"${id}",resource:"${this.contentResourceSelected}",isInitial:true}) {
                 contentResource2{
                id
              isInitial
                content
                resource{
                  id
                  name
                  file{
                    mediaDuration
                  }
                }
              }
              }
              `

                this.contentResourcesListForUpdate.forEach((element, i) => {
                  if (this.contentResourceSelected !== element) {
                    query += `a${i}: createContentsResource(input:{content:"${id}",resource:"${element}",isInitial:false}){
                  contentResource2{
                    id
                  isInitial
                    content
                    resource {
                      id
                      name
                    }
                    }
                  }`
                  }
                })
                query += '}'
                axios
                  .post('', {
                    query,
                  })
                  .then(r => {
                    messageError(r, this)

                    try {
                      let duration = result.data.data.inicial.contentResource2.resource.file
                        .mediaDuration

                      duration = duration !== 0 && duration != null
                        ? duration.file.mediaDuration
                        : this.duration * 60
                      axios
                        .post('', {
                          query: `mutation($id : ID!, $duration: Int){
                        updateContents(id:$id,input:{duration:$duration}){
                          content{
                            id
                            name
                            duration
                          }
                        }
                      }`,
                          variables: {
                            id,
                            duration: duration || 0,
                          },
                        })
                        .then(response => {
                          messageError(response, this)

                          this.showResource = false
                        })
                        .catch(() => {
                          this.showResource = false
                        })
                    } catch (error) {
                      this.showResource = false
                    }
                  })
                  .catch(err => {
                    console.log(err)
                  })
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
             query{
             client(id:"${this.userData.profile.client.id}"){
                 defaultLanguage
                 availableLanguages
                 platformMode
             }
         }
         `,
        })
        .then(res => {
          this.defaultLanguage = res.data.data.client.defaultLanguage
          this.platformMode = res.data.data.client.platformMode
        })
        .catch(() => {
        })
    },
    goToInternal() {
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('internoInfo'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          cancelButton: 'btn btn-outline-danger ml-1',
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showDesign = true

          axios
            .post('', {
              query: `
              mutation{
                contentToInternal(contentId:"${this.$route.params.id}"){
                  success
                }                
              }
              `,
            }, {
              headers,
            })
            .then(res => {
              this.showDesign = false
              messageError(res, this)
              showToast(this.$t('success'), 1, this)

              this.getContentInfo()
            })
            .catch(() => {
              showToast(this.$t('error'), 0, this)
            })
        }
      }).catch(err => { console.log(err) })
    },
    updateDesignContent(event) {
      return new Promise(resolve => {
        this.$refs.ArchivosRules.validate().then(success => {
          const headers = {
            'Accept-Language': camelToSnake(this.language),
            'Content-Language': camelToSnake(this.language),
          }
          const pass = !!(this.mediaOriginSelected === 'BUK' && this.imageContentSelected)

          if (success || pass) {
            resolve(true)
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            this.showDesign = true
            event.preventDefault()
            const { id } = this.$route.params

            if (this.mediaOriginSelected === 'BUK') {
              this.imageDataUrl = ''
              this.imageDataMobUrl = ''
              this.imageDataBckUrl = ''
              this.imageDataAltUrl = ''
              this.backgroundMobileUrl = ''
              this.imageDataTitUrl = ''
              this.imageDataTrUrl = ''

              let objectVariables = []
              objectVariables = [
                { title: `imageUrl${this.language}`, value: this.imageDataUrl, type: 'String' },
                { title: `imageMobileUrl${this.language}`, value: this.imageDataMobUrl, type: 'String' },
                { title: `backgroundUrl${this.language}`, value: this.imageDataBckUrl, type: 'String' },
                { title: `alternativeImageUrl${this.language}`, value: this.imageDataAltUrl, type: 'String' },
                { title: `backgroundMobileUrl${this.language}`, value: this.backgroundMobileUrl, type: 'String' },
                { title: `titleImageUrl${this.language}`, value: this.imageDataTitUrl, type: 'String' },
                { title: `trailerUrl${this.language}`, value: this.imageDataTrUrl, type: 'String' },
              ]

              const variables = {}
              let mutation = 'mutation('
              let query = `{updateContents(id:"${id}",input:{\n
          mediaLocation: BUK,\n`
              objectVariables.forEach(element => {
                variables[element.title] = element.value
                mutation += `$${element.title}:${element.type},\n`
                query += `${element.title}:$${element.title},\n`
              })
              mutation += ')'
              query += `}){
                content{
                  id
                  name
                  image
                  titleImage
                  alternativeImage
                  imageMobile
                  background
                  trailer        
                }
              }
            }`
              query = mutation + query
              data.append('query', query)
              data.append('variables', JSON.stringify(variables))
              data.append(`image_${camelToSnake(this.language)}`, this.imageData)
              data.append(`image_mobile_${camelToSnake(this.language)}`, this.imageDataMob)
              data.append(`background_${camelToSnake(this.language)}`, this.imageDataBck)
              data.append(`alternative_image_${camelToSnake(this.language)}`, this.imageDataAlt)
              data.append(`background_mobile_${camelToSnake(this.language)}`, this.backgroundMobile)
              data.append(`title_image_${camelToSnake(this.language)}`, this.imageDataTit)
              data.append(`trailer_${camelToSnake(this.language)}`, this.imageDataTr)

              axios.post('', data, { headers }, config).then(createResponse => {
                messageError(createResponse, this)
                this.showDesign = false
                showToast(this.$t('code.updateData'), 1, this)
              }).catch(() => {
                this.showDesign = false
                showToast(this.$t('code.updateDataError'), 2, this)
              })
            } else {
              let objectVariables = []
              objectVariables = [
                { title: `imageUrl${this.language}`, value: this.imageDataUrl, type: 'String' },
                { title: `imageMobileUrl${this.language}`, value: this.imageDataMobUrl, type: 'String' },
                { title: `backgroundUrl${this.language}`, value: this.imageDataBckUrl, type: 'String' },
                { title: `alternativeImageUrl${this.language}`, value: this.imageDataAltUrl, type: 'String' },
                { title: `backgroundMobileUrl${this.language}`, value: this.backgroundMobileUrl, type: 'String' },
                { title: `titleImageUrl${this.language}`, value: this.imageDataTitUrl, type: 'String' },
                { title: `trailerUrl${this.language}`, value: this.imageDataTrUrl, type: 'String' },
              ]

              const variables = {}
              let mutation = 'mutation('
              let query = `{updateContents(id:"${id}",input:{\n
          mediaLocation: DIR,\n`
              objectVariables.forEach(element => {
                variables[element.title] = element.value
                mutation += `$${element.title}:${element.type},\n`
                query += `${element.title}:$${element.title},\n`
              })
              mutation += ')'
              query += `}){
          content{
            id
            name            
          }
        }
      }`
              query = mutation + query
              data.append('query', query)
              data.append('variables', JSON.stringify(variables))

              axios
                .post('', data, { headers }, config)
                .then(res => {
                  messageError(res, this)

                  this.showDesign = false

                  showToast(this.$t('code.updateData'), 1, this)
                })
                .catch(() => {
                  this.showDesign = false

                  showToast(this.$t('code.updateDataError'), 2, this)
                })
            }
          }
        })
      })
    },
    updateRestrictions(type) {
      let query = ''
      const { id } = this.$route.params

      if (type === 0) {
        this.showRestrictionsOne = true
        query = `mutation{
          updateContents(id:"${id}", input:{
            nDisplay:${this.nDisplay},
            isDisplay:${this.isDisplay},
              }){
              content{
                id
                name
                nDisplay
                isDisplay
                
              }

            }          
        }`
      } else {
        this.showRestrictionsTwo = true
        query = `mutation{
          updateContents(id:"${id}", input:{
            maxViews:${this.maxViews},
              }){
              content{
                id
                name
                maxViews
              }
            }          
        }`
      }

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('code.cat', {
              data: res.data.data.updateContents.content.name,
            }),
            1, this,
          )
          this.showRestrictionsOne = false
          this.showRestrictionsTwo = false
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('code.updateDataError'), 2, this)
          this.showRestrictionsTwo = false
          this.showRestrictionsOne = false
        })
    },
    startLive() {
      const { id } = this.$route.params
      manageLive(
        id,
        'startLive',
        this.$t('live.startLive.successMsg'),
        this.$t('live.startLive.failMsg'),
        'PlayCircleIcon',
        this,
      ).then(result => {
        if (result) {
          this.updateReactiveStateStream('STR')
        }
      })
    },

    stopLive() {
      const { id } = this.$route.params
      manageLive(
        id,
        'stopLive',
        this.$t('live.stopLive.successMsg'),
        this.$t('live.stopLive.failMsg'),
        'PauseCircleIcon',
        this,
      ).then(result => {
        if (result) {
          this.updateReactiveStateStream('STP')
        }
      })
    },

    updateReactiveStateStream(stateStream) {
      Vue.set(this, 'stateStream', stateStream)
    },

    updateStateContent(event) {
      this.showState = true
      const isActive = !!(this.contentStatus === 'GRE' || this.contentStatus === 'YEW')

      const active = this.contentStatus === 'GRE'
      event.preventDefault()
      const { id } = this.$route.params
      const dateIn = this.fechaFormato(this.publishingDate)
      const dateFin = this.fechaFormato(this.finishingDate)

      if (this.contentSlide !== 0 && !active) {
        this.$swal({
          title: this.$t('dataGeneric.atention'),
          text: this.$t('desContSlider'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('next'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            axios
              .post('', {
                query: `mutation{
                updateContents(id:"${id}", input:{
                  state: ${this.contentStatus},
                  isActive:${isActive},              
                }){
                  content{
                    id
                    name
                    state
                  }
                }
              }
            `,
              })
              .then(res => {
                messageError(res, this)
                deactivateSlider([id], false, 'CONT')
                showToast(
                  this.$t('code.cat', {
                    data: res.data.data.updateContents.content.name,
                  }),
                  1, this,
                )

                this.labelStatus = this.contentStatus
                this.getContentInfo()

                this.showState = false
              })
              .catch(error => {
                console.log(error)

                showToast(this.$t('code.updateDataError'), 2, this)
                this.showState = false
              })
          } else {
            this.showState = false
          }
        }).catch(() => {
        })
      } else {
        axios
          .post('', {
            variables: {
              publishDate: dateIn,
              expirationDate: dateFin,
            },
            query: `
          ${this.contentStatus === 'GRE'
    ? 'mutation($publishDate: DateTime,$expirationDate: DateTime)'
    : 'mutation'
}
           {
            updateContents(id:"${id}", input:{
              state: ${this.contentStatus},
              isActive:${isActive},
              ${this.contentStatus === 'GRE'
    ? ` publishDate: $publishDate,
              expirationDate: $expirationDate,`
    : ''
}
              }){
              content{
                id
                name
                state
                publishDate
                expirationDate
              }

            }
          }
        `,
          })
          .then(res => {
            messageError(res, this)

            showToast(
              this.$t('code.cat', {
                data: res.data.data.updateContents.content.name,
              }),
              1, this,
            )

            this.labelStatus = this.contentStatus
            this.getContentInfo()

            this.showState = false
          })
          .catch(error => {
            console.log(error)

            showToast(this.$t('code.updateDataError'), 2, this)
            this.showState = false
          })
      }
    },
    getDataByAI(data) {
      const {
        description, name, technicalDetails, imageFolder, imageBackground, imageMobile, imageBackgroundMobile, imageAlternative, imageTitle, seoTitle, seoDescription, shortDescription,
      } = data

      if (description) {
        this.contentDescription = description
        this.$refs.descriptionDiv.classList.add('fill-field')
      }
      if (name) {
        this.contentName = limitCharacter(name, 150)
        this.$refs.nameDiv.classList.add('fill-field')
      }
      if (technicalDetails) {
        this.technicalDetails = technicalDetails
        this.$refs.technicalDetailsDiv.classList.add('fill-field')
      }
      if (shortDescription) {
        this.shortDescription = limitCharacter(shortDescription, 180)
        this.$refs.shortDescriptionDiv.classList.add('fill-field')
      }
      if (seoTitle) {
        this.seoTitle = limitCharacter(seoTitle, 80)
        this.$refs.seoTitleDiv.classList.add('fill-field')
      }
      if (seoDescription) {
        this.seoDescription = limitCharacter(seoDescription, 180)
        this.$refs.seoDescriptionDiv.classList.add('fill-field')
      }
      if (imageFolder) {
        urlToFile(imageFolder, 'image').then(result => {
          this.imageData = result
        }).catch(err => {
          console.error(err)
        })
        this.imageContentSelected = imageFolder
      }
      if (imageBackground) {
        urlToFile(imageBackground, 'image').then(result => {
          this.imageDataBck = result
        }).catch(err => {
          console.error(err)
        })

        this.backgroundContentSelected = imageBackground
      }
      if (imageMobile) {
        urlToFile(imageMobile, 'image').then(result => {
          this.imageDataMob = result
        }).catch(err => {
          console.error(err)
        })

        this.mobileImageContentSelected = imageMobile
      }
      if (imageBackgroundMobile) {
        urlToFile(imageBackgroundMobile, 'image').then(result => {
          this.backgroundMobile = result
        }).catch(err => {
          console.error(err)
        })

        this.backgroundMobileSelected = imageBackgroundMobile
      }

      if (imageAlternative) {
        urlToFile(imageAlternative, 'image').then(result => {
          this.imageDataAlt = result
        }).catch(err => {
          console.error(err)
        })

        this.imageAlternativeSelected = imageAlternative
      }
      if (imageTitle) {
        urlToFile(imageTitle, 'image').then(result => {
          this.imageDataTit = result
        }).catch(err => {
          console.error(err)
        })

        this.titleImageContentSelected = imageTitle
      }
    },
    async getInfoToTranslate(view) {
      const { id } = this.$route.params

      return axios
        .post('', {
          query: `
            {
              allContents(id:"${id}"){                  
                edges {
                  node {
                    ${view === 'info' ? `
                      name(lang:"${this.base}")
                      shortDescription(lang:"${this.base}")
                      description(lang:"${this.base}")
                      technicalDetails(lang:"${this.base}")
                    ` : `
                      seoTitle(lang:"${this.base}")
                      seoDescription(lang:"${this.base}")
                    `}
                  }
                }
              }
            }
          `,
        })
        .then(res => {
          messageError(res, this) // Maneja el mensaje de error
          const content = res.data.data.allContents.edges[0] // Obtén el contenido
          return content.node
        })
        .catch(error => {
          console.error(error)
          return null
        })
    },

    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case `imageData${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageData = data
          else this.imageDataUrl = data
          break
        case `imageDataBck${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageDataBck = data
          else this.imageDataBckUrl = data
          break
        case `imageDataMob${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageDataMob = data
          else this.imageDataMobUrl = data
          break
        case `backgroundMobile${this.language}`:

          if (this.mediaOriginSelected === 'BUK') this.backgroundMobile = data
          else this.backgroundMobileUrl = data
          break
        case `imageDataAlt${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageDataAlt = data
          else this.imageDataAltUrl = data
          break
        case `imageDataTit${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageDataTit = data
          else this.imageDataTitUrl = data
          break
        case `imageDataTr${this.language}`:
          if (this.mediaOriginSelected === 'BUK') this.imageDataTr = data
          else this.imageDataTrUrl = data
          break

        default:
          break
      }
    },
    saveFieldSeo() {
      this.seoTitle = isEmpty(this.seoTitle) ? this.contentName : this.seoTitle
      this.seoDescription = isEmpty(this.seoDescription) ? this.shortDescription : this.seoDescription
      this.updateSeo(false)
    },
    /* Funcionamiento botones hora, min, y seg */
    timePick() {
      const silencePromise = function (value) {
        if (value != null && typeof value.then === 'function') {
          value.then(null, () => { })
        }
      }

      const wasPlaying = !this.video.paused()
      if (wasPlaying) this.video.pause()

      const h = parseInt(this.tHour, 10) * 3600
      const m = parseInt(this.tMinute, 10) * 60
      let seekTime = parseInt(h, 10) + parseInt(m, 10) + parseInt(this.tSecond, 10)

      const duration = this.video.duration()
      if (seekTime >= duration) {
        seekTime = wasPlaying ? duration - 0.001 : duration
      }
      this.video.currentTime(seekTime)
      if (wasPlaying) silencePromise(this.video.play())
    },
    videoMaker() {
      this.video = videojs('demo')
      this.video.on('ready', function () {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
      this.video.on('timeupdate', () => { })
      const time = this.video.duration()

      if (!Number.isNaN(time)) {
        this.hour = Math.floor(time / 3600)
        this.minute = Math.floor((time / 60) % 60)
        this.minute = this.minute < 10 ? `0${this.minute}` : this.minute
        this.second = time % 60
        this.second = this.second < 10 ? `0${this.second}` : `${this.second}`

        try {
          const second = this.second.split('.')[0]
          this.second = second
          // eslint-disable-next-line no-empty
        } catch (error) { }
      }
    },

    guardarPortada(event) {
      const headers = {
        'Accept-Language': camelToSnake(this.language),
        'Content-Language': camelToSnake(this.language),
      }
      this.showResource = true

      event.preventDefault()
      const { id } = this.$route.params

      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
      mutation{
        updateContents(id:"${id}",input:{
          mediaLocation: ${this.mediaOriginSelected}
        }){
              content{
                id
                name
                image
                titleImage
                alternativeImage
                mediaLocation
                imageMobile
                background
                trailer
              }
            }
          }
     `
      data.append('query', query)

      switch (this.portadaSelect) {
        case 'PORT':
          data.append(`image_${camelToSnake(this.language)}`, this.imageBlob, 'image.png')
          break
        case 'ALT':
          data.append(`alternative_image_${camelToSnake(this.language)}`, this.imageBlob, 'image.png')
          break
        case 'FOND':
          data.append(`background_${camelToSnake(this.language)}`, this.imageBlob, 'image.png')
          break
        case 'MOV':
          data.append(`image_mobile_${camelToSnake(this.language)}`, this.imageBlob, 'image.png')
          break
        default:
          break
      }

      axios
        .post('', data, { headers }, config)
        .then(createResponse => {
          messageError(createResponse, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.getContentInfo()
          this.showResource = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('code.updateDataError'), 2, this)
          this.showResource = false
        })
    },
    clkSelectAll() {
      this.targetWebActive = this.selectAll
      this.targetHbbtvActive = this.selectAll
      this.targetMobileActive = this.selectAll
      this.targetSmarttvActive = this.selectAll
    },
    setTime() {
      const silencePromise = function (value) {
        if (value != null && typeof value.then === 'function') {
          value.then(null, () => { })
        }
      }

      const wasPlaying = !this.video.paused()
      if (wasPlaying) this.video.pause()

      let seekTime = this.tHour * 3600 + this.tMinute * 60 + this.tSecond
      const duration = this.video.duration()
      if (seekTime >= duration) {
        seekTime = wasPlaying ? duration - 0.001 : duration
      }
      this.video.currentTime(seekTime)
      if (wasPlaying) silencePromise(this.video.play())
    },
    updateSegmentationContent(event) {
      event.preventDefault()
      const { id } = this.$route.params

      axios
        .post('', {
          query: `mutation{
            updateContents(id:"${id}", input:{
              targetWebActive: ${this.targetWebActive},
              targetHbbtvActive: ${this.targetHbbtvActive},
              targetMobileActive: ${this.targetMobileActive},
              targetSmarttvActive: ${this.targetSmarttvActive},
            }){
              content{
                id
                name
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)
          showToast(
            this.$t('code.cat', {
              data: res.data.data.updateContents.content.name,
            }),
            1, this,
          )
          this.getContentInfo()
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('code.updateDataError'), 2, this)
        })
    },
    tiempoVideo(tipo = null) {
      let silencePromise = null
      let wasPlaying = null
      let seekTime = null
      let duration = null
      switch (tipo) {
        case '-10':
          silencePromise = function (value) {
            if (value !== null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying = !this.video.paused()
          if (wasPlaying) this.video.pause()

          seekTime = this.video.currentTime() - 10
          if (seekTime <= 0) {
            seekTime = 0
          }
          this.video.currentTime(seekTime)
          if (wasPlaying) silencePromise(this.video.play())

          break
        case '-1':
          silencePromise = function (value) {
            if (value !== null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying = !this.video.paused()
          if (wasPlaying) this.video.pause()

          seekTime = this.video.currentTime() - 1
          if (seekTime <= 0) {
            seekTime = 0
          }
          this.video.currentTime(seekTime)
          if (wasPlaying) silencePromise(this.video.play())

          break
        case '+10':
          silencePromise = function (value) {
            if (value != null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying = !this.video.paused()
          if (wasPlaying) this.video.pause()

          seekTime = this.video.currentTime() + 10
          duration = this.video.duration()
          if (seekTime >= duration) {
            seekTime = wasPlaying ? duration - 0.001 : duration
          }
          this.video.currentTime(seekTime)
          if (wasPlaying) silencePromise(this.video.play())

          break
        case '+1':
          silencePromise = function (value) {
            if (value != null && typeof value.then === 'function') {
              value.then(null, () => { })
            }
          }

          wasPlaying = !this.video.paused()
          if (wasPlaying) this.video.pause()

          seekTime = this.video.currentTime() + 1
          duration = this.video.duration()
          if (seekTime >= duration) {
            seekTime = wasPlaying ? duration - 0.001 : duration
          }
          this.video.currentTime(seekTime)
          if (wasPlaying) silencePromise(this.video.play())

          break

        default:
          break
      }
    },
    removeFillFieldClasses() {
      const elements = document.getElementsByClassName('fill-field')

      Array.from(elements).forEach(element => {
        element.classList.remove('fill-field')
      })
    },
    genre() {
      axios
        .post('', {
          query: `
            query{
            allGenre(client:"${this.userData.profile.client.id}") {
              edges {
                node {
                  id
                  name
                  description
                  isActive

                }
              }
            }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allGenre.edges.forEach(element => {
            if (element.node.isActive) {
              this.allGenre.push({
                value: element.node.id,
                text: element.node.name,
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    clasification() {
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
          query{
            allClasification {
              edges {
                node {
                 id
                type
                initial
                description
                color
                clasificationsClient {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allClasification.edges.forEach(element => {
            if (element.node.clasificationsClient.edges[0] != null) {
              element.node.clasificationsClient.edges.forEach(a => {
                if (a.node.id === id) {
                  this.allClasification.push(element)
                }
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    pad(n, size) {
      let s = String(n)
      while (s.length < (size || 2)) {
        s = `0${s}`
      }
      return s
    },
    getTime() {
      try {
        const time = this.video.currentTime()
        if (!Number.isNaN(time)) {
          this.tHour = Math.floor(time / 3600)
          this.tHour = this.tHour < 10 ? this.tHour : this.tHour
          this.tHour = this.pad(this.tHour)
          this.tMinute = Math.floor((time / 60) % 60)
          this.tMinute = this.tMinute < 10 ? this.tMinute : this.tMinute
          this.tMinute = this.pad(this.tMinute)
          this.tSecond = (time % 60).toFixed()
          this.tSecond = this.pad(this.tSecond)
        }
        // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },
    async generar() {
      document.getElementById('colCanvas').style.display = 'block'

      this.gnBut = true
      const video = document.getElementById('demo_html5_api')

      this.canvas = document.getElementById('canvas')

      this.canvas.width = video.videoWidth
      this.canvas.height = video.videoHeight

      this.canvas
        .getContext('2d')
        .drawImage(video, 0, 0, this.canvas.width, this.canvas.height)

      this.imageBlob = await new Promise(resolve => this.canvas.toBlob(resolve, 'image/png'))
    },

    buildImageUrl(folder) {
      return folder === null || folder.length === 0
        ? noCover
        : folder
    },
    deleteImage(type) {
      const headers = {
        'Accept-Language': camelToSnake(this.language),
        'Content-Language': camelToSnake(this.language),
      }
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.showDesign = true
            const config = {
              timeout: 1000 * 60 * 30,
            }
            const data = new FormData()
            const { id } = this.$route.params
            if (this.mediaOriginSelected === 'BUK') {
              const query = `
                mutation{
                  updateContents(id:"${id}",input:{
                    mediaLocation: BUK
                  }){
                        content{
                          id
                          name
                          image
                          titleImage
                          alternativeImage
                          imageMobile
                          background
                          trailer
                        }
                      }
                    }
              `
              data.append('query', query)

              switch (type) {
                case `imageDataAlt${this.language}`:
                  this.imageAlternativeSelected = null
                  data.append('deleteFile', `alternative_image_${camelToSnake(this.language)}`)

                  break
                case `imageDataMob${this.language}`:
                  this.mobileImageContentSelected = null
                  data.append('deleteFile', `image_mobile_${camelToSnake(this.language)}`)

                  break
                case `imageDataBck${this.language}`:
                  this.backgroundContentSelected = null
                  data.append('deleteFile', `background_${camelToSnake(this.language)}`)

                  break
                case `imageDataTit${this.language}`:
                  this.titleImageContentSelected = null
                  data.append('deleteFile', `title_image_${camelToSnake(this.language)}`)

                  break
                case `backgroundMobile${this.language}`:
                  this.backgroundMobileSelected = null
                  data.append('deleteFile', `background_mobile_${camelToSnake(this.language)}`)

                  break
                case `imageDataTr${this.language}`:
                  this.trailerContentContentSelected = null
                  data.append('deleteFile', `trailer_${camelToSnake(this.language)}`)

                  break

                default:
                  break
              }
              axios.post('', data, { headers }, config).then(createResponse => {
                messageError(createResponse, this)
                this.showDesign = false

                showToast(this.$t('success'), 1, this)
              }).catch(() => {
                this.showDesign = false

                showToast(this.$t('code.updateDataError'), 2, this)
              })
            } else {
              let objectVariables = []

              switch (type) {
                case 'imageDataAlt':
                  this.imageDataAltUrl = ''
                  objectVariables = [
                    { title: `alternativeImageUrl${this.language}`, value: this.imageDataAltUrl, type: 'String' },
                  ]

                  break
                case 'imageDataMob':
                  this.imageDataMobUrl = ''
                  objectVariables = [
                    { title: `imageMobileUrl${this.language}`, value: this.imageDataMobUrl, type: 'String' },
                  ]

                  break
                case 'imageDataBck':
                  this.imageDataBckUrl = ''

                  objectVariables = [
                    { title: `backgroundUrl${this.language}`, value: this.imageDataBckUrl, type: 'String' },
                  ]

                  break
                case 'imageDataTit':
                  this.imageDataBckUrl = ''

                  objectVariables = [
                    { title: `titleImageUrl${this.language}`, value: this.imageDataTitUrl, type: 'String' },
                  ]

                  break
                case 'backgroundMobile':
                  this.backgroundMobileUrl = ''

                  objectVariables = [
                    { title: `backgroundMobileUrl${this.language}`, value: this.backgroundMobileUrl, type: 'String' },
                  ]

                  break
                case 'imageDataTr':
                  this.imageDataTrUrl = ''

                  objectVariables = [
                    { title: `trailerUrl${this.language}`, value: this.imageDataTrUrl, type: 'String' },
                  ]

                  break

                default:
                  break
              }

              const variables = {}
              let mutation = 'mutation('
              let query = `{updateContents(id:"${id}",input:{\n
          mediaLocation: DIR,\n`
              objectVariables.forEach(element => {
                variables[element.title] = element.value
                mutation += `$${element.title}:${element.type},\n`
                query += `${element.title}:$${element.title},\n`
              })
              mutation += ')'
              query += `}){
                  content{
                    id
                    name            
                  }
                }
              }`
              query = mutation + query
              data.append('query', query)
              data.append('variables', JSON.stringify(variables))

              axios
                .post('', data, { headers }, config)
                .then(res => {
                  messageError(res, this)

                  this.showDesign = false

                  showToast(this.$t('code.updateData'), 1, this)
                })
                .catch(error => {
                  console.log(error)
                  this.showDesign = false
                  showToast(this.$t('code.updateDataError'), 2, this)
                })
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    CategorySelection(data) {
      if (this.typeCat === 'main') {
        this.categoryName = data.node.name
        this.categoryId = data.node.id
        this.imageCat = data.node.imageUrl
      } else {
        this.seasonCategoryName = data.node.name
        this.seasonCategoryId = data.node.id
      }
      this.hideModal()
    },
    showModalCat(type) {
      this.typeCat = type
      this.$refs['cat-modal'].show()
    },
    showMessage() {
      this.$swal({
        title: this.$t('catMain'),
        text: this.$t('establecer'),
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: false,
        confirmButtonText: this.$t('select'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        showLoaderOnConfirm: true,
      }).then(() => {
        this.indexTabs = 6
      })
    },
    SelectContentId(data) {
      this.previousChapterId = data
      this.hideModal()
    },
    SelectContentUrl(data) {
      this.previousChapterName = data
      this.hideModal()
    },
    changePreviousContent() {
      const idAntiguo = this.previousChapter != null ? this.previousChapter.id : null

      let query = 'mutation{'
      query += `q1: 
                updateContents(id:"${this.$route.params.id}",input:{previousChapter:"${this.previousChapterId}"}){
                  content {
                    id
                    name
                  }
                }
              `
      query += `
                q2: updateContents(id:"${this.previousChapterId}",input:{nextChapter:"${this.$route.params.id}"}){
                  content {
                    id
                    name
                  }
                }
              `
      if (idAntiguo != null) {
        query += `
                 q3: updateContents(id:"${idAntiguo}",input:{nextChapter:""}){
                   content {
                     id
                     name
                   }
                 }
               `
        if (this.previousChapter.previousChapter != null) {
          if (
            this.previousChapter.previousChapter.id === this.previousChapterId
          ) {
            query += `
                q4: updateContents(id:"${idAntiguo}",input:{previousChapter:""}){
                  content {
                    id
                    name
                  }
                }
              `
          }
        }
      }
      query += '}'

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('code.cat', {
              data: this.contentName,
            }),
            1, this,
          )
        })
        .catch(error => {
          showToast(this.$t('code.updateDataError'), 2, this)

          console.log(error)
        })
    },

    deleteChapter(type) {
      this.$swal({
        title: this.$t('sure?'),
        text: this.$t('contRel', { name: type === 0 ? this.previousChapter.name : this.nextChapter.name }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yesRemove'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            let query = 'mutation{'
            if (type === 0) {
              query += `q1: 
                updateContents(id:"${this.$route.params.id}",input:{previousChapter:""}){
                  content {
                    id
                    name
                  }
                }
              `
              query += `
                q2: updateContents(id:"${this.previousChapterId}",input:{nextChapter:""}){
                  content {
                    id
                    name
                  }
                }
              `
              this.previousChapter = null
              this.previousChapterId = null
              this.previousChapterName = null
            } else {
              query += `q1: 
                updateContents(id:"${this.$route.params.id}",input:{nextChapter:""}){
                  content {
                    id
                    name
                  }
                }
              `
              query += `
                q2: updateContents(id:"${this.nextChapterID}",input:{previousChapter:""}){
                  content {
                    id
                    name
                  }
                }
              `
              this.nextChapter = null
              this.nextChapterID = null
              this.nextChapterName = null
            }

            query += '}'

            axios
              .post('', {
                query,
              })
              .then(res => {
                messageError(res, this)

                showToast(
                  this.$t('code.cat', {
                    data: this.contentName,
                  }),
                  1, this,
                )
              })
              .catch(error => {
                showToast(this.$t('code.updateDataError'), 2, this)

                console.log(error)
              })
          }
        })
        .catch(() => { })
    },
    catEspecial(cat) {
      this.$swal({
        title: this.$t('specialCategoryTitle'),
        text: this.$t('specialCategoryMsg', { category_name: cat.node.name, category_type: this.$t(this.categoryTypes[cat.node.type]) }),
        icon: 'info',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {

      }).catch(() => {

      })
    },
    getElement(id = null, link = null) {
      if (id != null) {
        if (link === 'LIN') this.$router.push({ name: 'link-categories', params: { id, link: 'LIN' } })
        else { this.$router.push({ name: 'contents-subcategories', params: { id } }) }
      } else {
        this.$router.push({ name: 'contents-categories' })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#editarContenido .card-body {
  padding: 1.3rem;
}

#editarContenido .card-title {
  font-weight: 500;
}

#editarContenido #add-new-category-sidebar {
  #editarContenido .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#editarContenido .file-input {
  display: none;
}

#editarContenido .modal {
  overflow-y: auto;
}

#editarContenido .tamanio {

  width: auto;
  height: auto;
}

/*
Full screen Modal
*/
#editarContenido .fullscreen-modal .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 768px) {
  #editarContenido .fullscreen-modal .modal-dialog {
    width: 750px;
  }
}

@media (min-width: 992px) {
  #editarContenido .fullscreen-modal .modal-dialog {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #editarContenido .fullscreen-modal .modal-dialog {
    width: 1170px;
  }
}

#editarContenido .video-js .vjs-tech {
  position: inherit;

  height: auto;
}

#editarContenido #colCanvas {
  box-sizing: border-box;
  width: 50%;
  padding: 10px;
}

#editarContenido #canvas {
  width: 100%;
}

#editarContenido .tableImage {
  margin-right: 2rem;
  border-radius: 10px;
  width: 8rem;
}

#editarContenido .tableHeight {
  height: 700px;
}

#editarContenido .views-info {
  display: flex;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

#editarContenido .infoBadge {
  width: -webkit-fill-available;
  font-size: initial;
  line-height: inherit;
  white-space: break-spaces;
  text-align: inherit;
}

.fill-field {
  border: 2px solid transparent;
  border-radius: 0.357rem;
  background: linear-gradient(58.28deg, #7a57fd -14.11%, #ff38b0 80.9%) border-box;
  background-size: 200% 200%;
  /* Permite que el gradiente se mueva */
  background-clip: border-box;
  animation: borderGradientMovement 1s ease-in-out infinite, grow 0.8s ease forwards;
  /* Animación continua */
  position: relative;
  filter: brightness(1.1);
  /* Aumenta el brillo */
}

@keyframes borderGradientMovement {
  0% {
    background-position: 0% 50%;
    /* Empieza desde la esquina superior izquierda */
  }

  50% {
    background-position: 100% 50%;
    /* Se mueve hacia la derecha */
  }

  100% {
    background-position: 0% 50%;
    /* Vuelve al inicio */
  }
}

.field {
  border-radius: 0.357rem;
}
</style>
